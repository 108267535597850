import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Box,
  Button,
  Flex,
  Input,
  Select,
  Text,
  Image,
  useDisclosure,
  FormErrorMessage,
  FormControl,
  FormLabel,
  SimpleGrid,
  useToast,
} from '@chakra-ui/react'
import * as Yup from 'yup'
import DatePicker from 'react-datepicker'
import { yupResolver } from '@hookform/resolvers/yup'
import { BiEdit, BiFilter, BiStore, BiUser, BiWallet } from 'react-icons/bi'
import IHabitational_Unity from '../../../interfaces/IHabitational_Unity'
import IEnterprises from '../../../interfaces/IEnterprises'
import IOwnership from '../../../interfaces/IOwnership'
import { useFetch } from '../../../hooks/useFetch'
import { useParams } from 'react-router-dom'
import moment from 'moment'

interface FormInputsProps {
  name: string
  value: string
  placeholder?: string
  label: string
  yup: any
}

const ConfirmModal = (enterprises: IEnterprises[], habitational_unity: IHabitational_Unity[], event: any) => {
  const formInputs: FormInputsProps[] = [
    {
      name: 'enterprise_id',
      value: '',
      placeholder: '',
      label: 'Empreedimento',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'which_one_hu',
      value: '',
      placeholder: '',
      label: 'Habitação',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'habitational_unity_id',
      value: '',
      placeholder: '',
      label: 'Unidade habitacional',
      yup: Yup.string(),
    },
    {
      name: 'start_at',
      value: '',
      placeholder: '',
      label: 'Inicio',
      yup: Yup.string()
        .required('Este campo é obrigatório!')
        .matches(/^\d{2}[./-]\d{2}[./-]\d{4}$/, 'Data inválida'),
    },
    {
      name: 'end_at',
      value: '',
      placeholder: '',
      label: 'Fim',
      yup: Yup.string()
        .required('Este campo é obrigatório!')
        .matches(/^\d{2}[./-]\d{2}[./-]\d{4}$/, 'Data inválida'),
    },
  ]

  const yupschema: any = {}
  const defaultValuesSchema: any = {}

  formInputs.forEach(object => {
    yupschema[object.name] = object.yup
    defaultValuesSchema[object.name] = object.value
  })
  const schema = Yup.object().shape(yupschema)

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  })
  const baseUrl = 'v1/ownership'
  const { requestApi, error, data } = useFetch(baseUrl)
  const toast = useToast()
  const { itemId } = useParams()

  const onSubmit = async (values: any) => {
    const vv: IOwnership = values
    console.log('values', vv.start_at)

    try {
      const response = await requestApi(
        'post',
        {
          ...vv,
          user_id: itemId,
          start_at: moment(vv.start_at, 'DD/MM/YYYY'),
          end_at: moment(vv.end_at, 'DD/MM/YYYY'),
        },
        `${baseUrl}`,
      )

      if (response.status < 299) {
        toast({
          title: `Sucesso!`,
          position: 'top',
          status: 'success',
          isClosable: true,
        })
        if (!error) {
          onClose()
          event()
        } else {
          onClose()
          toast({
            title: `Ocorreu um erro ae executar a ação! ${error.message}`,
            position: 'top',
            status: 'error',
            isClosable: true,
          })
        }
      } else {
        onClose()
        console.log('error', error)
        toast({
          title: `Ocorreu um erro ae executar a ação! ${error.error}`,
          position: 'top',
          status: 'error',
          isClosable: true,
        })
      }
    } catch (error: any) {
      console.log('error', error)
      toast({
        title: `Ocorreu um erro ae executar a ação! ${error.error}`,
        position: 'top',
        status: 'error',
        isClosable: true,
      })
      onClose()
    }
  }

  const enterprise_id = formInputs.find(field => field.name === 'enterprise_id')
  const which_one_hu = formInputs.find(field => field.name === 'which_one_hu')
  const habitational_unity_id = formInputs.find(field => field.name === 'habitational_unity_id')
  const start_at = formInputs.find(field => field.name === 'start_at')
  const end_at = formInputs.find(field => field.name === 'end_at')

  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button onClick={onOpen} colorScheme="red" rightIcon={<BiStore />} variant="solid">
        Adicionar
      </Button>
      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Adicionar propriedade</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <Text mb="1rem">Informações</Text>
              <Box maxW="md" mb="5">
                <FormControl isInvalid={errors.enterprise_id && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    {enterprise_id?.label}
                  </FormLabel>
                  <Select isRequired {...register('enterprise_id')}>
                    {enterprises.map((group, index) => (
                      <option key={index} value={group.enterprise_id}>
                        {group.enterprise_name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{`${errors.enterprise_id?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mb="5">
                <FormControl isInvalid={errors.habitational_unity_id && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    {habitational_unity_id?.label}
                  </FormLabel>
                  <Select isRequired {...register('habitational_unity_id')}>
                    {habitational_unity.map((group, index) => (
                      <option key={index} value={group.habitational_unity_id}>
                        {group.room_type} - {group.pax} acomodações
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{`${errors.habitational_unity_id?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mb="5">
                <FormControl isInvalid={errors.which_one_hu && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Habitação
                  </FormLabel>
                  <Input id="which_one_hu" isRequired {...register('which_one_hu')} />
                  <FormErrorMessage>{`${errors.which_one_hu?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mb="5">
                <FormControl isInvalid={errors.start_at && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Inicia em
                  </FormLabel>
                  <Input
                    mask="99/99/9999"
                    as={InputMask}
                    id="start_at"
                    placeholder={start_at?.label}
                    isRequired
                    {...register('start_at')}
                  />
                  <FormErrorMessage>{`${errors.start_at?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mb="5">
                <FormControl isInvalid={errors.end_at && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Termina em
                  </FormLabel>
                  <Input
                    mask="99/99/9999"
                    as={InputMask}
                    id="end_at"
                    placeholder={end_at?.label}
                    isRequired
                    {...register('end_at')}
                  />
                  <FormErrorMessage>{`${errors.end_at?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button mr={3} onClick={onClose} variant="ghost">
                Sair
              </Button>
              <Button type="submit">Gravar</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ConfirmModal
