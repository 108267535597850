import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Heading, Input, Select, Text, useToast } from '@chakra-ui/react'
import ReactTable from '../../components/ReactTable'
import { format } from 'date-fns'
import { ReturnButton } from '../../components/Buttons/ReturnButton'
import { BiEdit, BiFilter, BiStore, BiUser, BiWallet } from 'react-icons/bi'
import { useNavigate, useParams } from 'react-router-dom'
import IUsers from '../../interfaces/IUsers'
import IHabitational_Unity from '../../interfaces/IHabitational_Unity'
import IEnterprises from '../../interfaces/IEnterprises'
import { api } from '../../services/api'
import ConfirmModal from './modal/index'
import { useFetch } from '../../hooks/useFetch'
import ConfirmModalOwnership from '../../components/ConfirmModal/ConfirmModalOwnership'

const Ownership = () => {
  const baseUrl = '/v1/ownership/'
  const { requestApi, error } = useFetch('')
  const navigate = useNavigate()
  const [pageNumber, setPageNumber] = useState(1)
  const [usuario, setUsuario] = useState<any>({})
  const [pageSize, setPageSize] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [data, setData] = useState<IUsers[]>([])
  const [dataEnterprises, setDataEnterprises] = useState<IEnterprises[]>([])
  const [dataHabitationalUnity, setDataHabitationalUnity] = useState<IHabitational_Unity[]>([])
  const [total, setTotal] = useState(0)
  const [isOpen, onOpen] = useState(false)
  const toast = useToast()
  const { itemId } = useParams()

  useEffect(() => {
    getUsers()
  }, [pageNumber, perPage])

  const getUsers = async () => {
    try {
      const getOwnership = await api.get(`v1/ownership/${itemId}?pageNumber=${pageNumber}&perPage=${perPage}`)
      const getUser = await api.get(`v1/users/${itemId}`)
      const getEnterprises = await api.get(`v1/enterprises-all`)
      const getHabitationalUnity = await api.get(`v1/habitational_unity`)
      if (getOwnership.status === 200) {
        console.log('Aqui:::', getOwnership.data)
        setData(getOwnership.data.ownership)
        setUsuario(getUser.data)
        setDataHabitationalUnity(getHabitationalUnity.data.habitational_unity)
        setDataEnterprises(getEnterprises.data.enterprises)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteRegister = async (id: number) => {
    try {
      const deleteData = await api.delete(`v1/ownership/${id}`)

      if (deleteData.status === 200) {
        getUsers()
        toast({
          title: `Sucesso!`,
          position: 'top',
          status: 'success',
          isClosable: true,
        })
      } else {
        toast({
          title: `Ocorreu um erro ae executar a ação! ${deleteData.data.message}`,
          position: 'top',
          status: 'error',
          isClosable: true,
        })
      }
    } catch (error: any) {
      toast({
        title: `Ocorreu um erro ae executar a ação! ${error!.message}`,
        position: 'top',
        status: 'error',
        isClosable: true,
      })
      console.log(error)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Detalhes',
        columns: [
          {
            Header: 'Empreedimento',
            accessor: 'enterprise.enterprise_name',
          },
          {
            Header: 'Qual hu',
            accessor: 'which_one_hu',
          },
          {
            Header: 'unidade habitacional',
            accessor: 'habitational_unity.room_type',
            Cell: ({ row }: any) => {
              return (
                <Text>
                  {row.original.habitational_unity.room_type} - {row.original.habitational_unity.room_type}{' '}
                  acomodação(ões)
                </Text>
              )
            },
          },
          {
            Header: 'Inicia',
            accessor: 'start_at',
            Cell: ({ row }: any) => {
              return <Text>{format(new Date(row.original.start_at), 'dd/MM/yy')}</Text>
            },
          },
          {
            Header: 'Fim',
            accessor: 'end_at',
            Cell: ({ row }: any) => {
              return <Text>{format(new Date(row.original.end_at), 'dd/MM/yy')}</Text>
            },
          },
          {
            Header: 'Ações',
            accessor: 'actions',
            Cell: ({ row }: any) => (
              <Flex align="center">{ConfirmModalOwnership(() => deleteRegister(row.original.onwership_id))}</Flex>
            ),
          },
        ],
      },
    ],
    [],
  )

  return (
    <Flex flexDir="column" mt="7vh" ml="4vw" w="73vw">
      <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
        <Flex>
          <ReturnButton onClick={() => navigate(`/users/${itemId}/edit`)} />
          <Text mb="20px" fontSize="24px" fontWeight="bold" color="#2D3748">
            Empreedimentos do usuário: {usuario.user_firstname} {usuario.user_lastname}
          </Text>
          <div style={{ display: 'flex', width: '45%', justifyContent: 'right' }}>
            {ConfirmModal(dataEnterprises, dataHabitationalUnity, () => getUsers())}
          </div>
        </Flex>
        <Box>
          <ReactTable
            columns={columns}
            pageNumber={pageNumber}
            data={data}
            totaPages={pageSize}
            onChange={(e: any) => {
              console.log('Akkkk:: ', e)
              setPageNumber(e.pageIndex + 1)
              setPerPage(e.sPageSize)
            }}
          />
        </Box>
      </Box>
    </Flex>
  )
}
export default Ownership
