/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FaKey } from 'react-icons/fa'
import { BiLogIn } from 'react-icons/bi'
import { Alert, AlertIcon, Flex, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../providers/auth'

import { PasswordInputForm } from '../../inputs/PasswordInputForm'
import { UsernameInputForm } from '../../inputs/UsernameInputForm'
import AlertModal from '../../AlertModal'

import { FormButton } from '../../Buttons/FormButton'
import { InfoButton } from '../../Buttons/InfoButton'
import { api } from '../../../services/api'

export default function LoginForm() {
  const yupschema: any = {}
  const defaultValuesSchema: any = {}
  const { Login, error } = useAuth()
  const [error2, setError2] = React.useState('')
  const [isSucess, setIsSucess] = React.useState(false)
  const navigate = useNavigate()

  const validateEmail = (email: string | undefined) => {
    return Yup.string().email().isValidSync(email)
  }

  const validatePhone = (phone: string | undefined) => {
    return Yup.string()
      .matches(/^\([1-9]{2}\) (9[1-9])[0-9]{3}-[0-9]{4}/)
      .isValidSync(phone)
  }

  const formInputs = [
    {
      name: 'email',
      // value: 'adminuser@sensi.app',
      value: '',
      placeholder: '',
      label: 'Usuário',
      yup: Yup.string()
        .required('Campo obrigatório.')
        .test('username', 'Usuário é inválido', (value: any) => {
          return validateEmail(value) || validatePhone(value)
        }),
    },
    {
      name: 'password',
      // value: '123456',
      value: '',
      label: 'Senha',
      placeholder: '',
      yup: Yup.string().required('Campo obrigatório!'),
    },
  ]

  formInputs.forEach(object => {
    yupschema[object.name] = object.yup
    defaultValuesSchema[object.name] = object.value
  })
  const schema = Yup.object().shape(yupschema)

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  })

  const username = formInputs.find(field => field.name === 'email')
  const password = formInputs.find(field => field.name === 'password')

  const onSubmit = async (values: any) => {
    await Login(values)
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <>
        {error && (
          <div style={{ backgroundColor: '#fff' }}>
            <Alert status="error" mb="20px" borderRadius="5px">
              <AlertIcon />
              Login ou senha incorretos!
            </Alert>
          </div>
        )}
      </>

      <>
        {error2 !== '' && (
          <Alert status="error" mb="20px" borderRadius="5px">
            <AlertIcon />
            {error2}
          </Alert>
        )}
      </>

      <>
        {isSucess && (
          <Alert status="success" mb="20px" borderRadius="5px">
            <AlertIcon />
            Um e-mail foi enviado, vá até a caixa de e-mail e siga as instruções!
          </Alert>
        )}
      </>

      <UsernameInputForm
        name={username?.name}
        label={username?.label}
        styles={{ color: 'white', borderRadius: '150px' }}
        labelStyles={{ color: 'white' }}
        iconLeft={<BiLogIn color="white" size={25} />}
        infoButton={
          <AlertModal
            alertMsg={
              <Text textAlign="center" fontWeight="bold" fontSize="20px">
                Pode ser usado email ou número de telefone como usuário de acesso à plataforma.
              </Text>
            }
            msgStatus="info"
            ButtonOpen={InfoButton}
            buttonOpenStyles={{ color: 'white' }}
          />
        }
        placeholder={username?.placeholder}
        control={control}
        errors={errors}
        isRequired
      />

      <PasswordInputForm
        name={password?.name}
        label={password?.label}
        styles={{ color: 'white', background: 'none' }}
        labelStyles={{ color: 'white' }}
        buttonStyles={{ color: 'white' }}
        iconLeft={<FaKey color="white" />}
        placeholder={password?.placeholder}
        register={register}
        errors={errors}
        isRequired
      />

      <Flex justify="flex-end">
        <FormButton
          mt={5}
          onClick={async () => {
            const usernameInputValue = await watch('email')
            console.log(usernameInputValue)
            if (usernameInputValue === '') {
              setError2(
                'Informe o usuário para recuperar a senha  e  depois precione o  botão  de esqueceu  a  senha  novamente.',
              )
            } else {
              const res: any = await api
                .put(`/v1/request_update_password`, {
                  email: usernameInputValue,
                })
                .then(res => {
                  if (res.status === 200) {
                    setIsSucess(true)
                  }
                })
                .catch(err => {
                  console.log(err.response.status)
                  if (err.response.status === 404) {
                    setError2('Usuário não encontrado.')
                  } else {
                    setError2('Erro ao enviar e-mail.')
                  }
                })
            }
          }}
          bgColor="transparent"
          isLoading={isSubmitting}
          variant="link"
          boxShadow="none"
          color="white"
        >
          Esqueceu sua senha?
        </FormButton>
      </Flex>

      <FormButton mt={10} borderRadius="full" w="full" isLoading={isSubmitting} type="submit">
        Entrar
      </FormButton>
    </form>
  )
}
