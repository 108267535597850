/* eslint-disable react/no-children-prop */
import React from 'react'
import {
  HStack,
  Box,
  Spacer,
  Stack,
  Center,
  InputGroup,
  InputLeftElement,
  Input,
  Text,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
} from '@chakra-ui/react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { BiSearch, BiUser } from 'react-icons/bi'
import { IoIosArrowDown } from 'react-icons/io'
import { GrNotification } from 'react-icons/gr'
import { STORAGE_KEYS } from '../../constants/Config'
import { userType } from '../../utils/FormatFunctions'
import { AiOutlineApi } from 'react-icons/ai'
import { useAuth } from '../../providers/auth'

export default function NavBarAuth() {
  // const navigate = useNavigate()
  // console.log(navigate)
  const location = useLocation()
  const { itemId } = useParams()
  const { Logout } = useAuth()
  // const itemPage = location?.pathname !== undefined && location?.pathname
  const user = JSON.parse(localStorage.getItem(STORAGE_KEYS.AUTH as string) as string)
  const namePage = () => {
    if (location?.pathname === '/') {
      return 'Dashboard'
    }
    if (location?.pathname === '/users') {
      return 'Usuários'
    }
    if (location?.pathname === '/users/create') {
      return 'Usuários'
    }
    if (location?.pathname === `/users/${itemId}/edit`) {
      return 'Usuários'
    }
    if (location?.pathname === '/reservations') {
      return 'Reservas'
    }
    if (location?.pathname === '/exchanges') {
      return 'Intercâmbios'
    }
    if (location?.pathname === '/enterprises') {
      return 'Propriedades'
    }
    if (location?.pathname === '/general-settings') {
      return 'Configurações Gerais'
    }
  }

  const descriptionPage = () => {
    if (location?.pathname === '/') {
      return 'Início'
    }
    if (location?.pathname === '/users') {
      return 'Lista de usuários'
    }
    if (location?.pathname === '/users/create') {
      return 'Cadastro de usuários'
    }
    if (location?.pathname === `/users/${itemId}/edit`) {
      return 'Edição de usuários'
    }
    if (location?.pathname === '/reservations') {
      return 'Lista de reservas'
    }
    if (location?.pathname === '/exchanges') {
      return 'Lista de intercâmbios'
    }
    if (location?.pathname === '/enterprises') {
      return 'Lista de propriedades'
    }
    if (location?.pathname === '/general-settings') {
      return 'Configurações Gerais'
    }
  }

  return (
    <Box p={['15px 15px', '15px 0']} h="90px" position="relative">
      <Center>
        <HStack w="80vw">
          <Box>
            <Breadcrumb separator="/">
              <BreadcrumbItem>
                <BreadcrumbLink href="#">Páginas</BreadcrumbLink>
              </BreadcrumbItem>

              <BreadcrumbItem>
                <BreadcrumbLink fontWeight="medium" color="#000000" href="#">
                  {namePage()}
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <Breadcrumb separator="">
              <BreadcrumbItem>
                <BreadcrumbLink fontWeight="medium" fontSize="18px" color="#000000" href="#">
                  {descriptionPage()}
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          </Box>
          <Spacer />
          <Stack mt="20px" cursor="pointer" p="10px 20px" direction="row" align="center">
            <InputGroup>
              <InputLeftElement pointerEvents="none" children={<BiSearch color="gray.300" />} />
              <Input type="search" placeholder="Pesquisa geral" />
            </InputGroup>
          </Stack>

          <HStack spacing="30px">
            <Box
              display="flex"
              alignSelf="center"
              alignContent="center"
              alignItems="center"
              justifySelf="center"
              justifyContent="center"
              justifyItems="center"
              flexDir="row"
            >
              <Menu>
                <MenuButton>
                  <Box display="flex" alignItems={'center'} flexDir="row">
                    <BiUser size="30px" color="gray.300" />
                    <Box ml="20px">
                      <Text fontWeight="medium" fontSize="16px" color="#718096">
                        {user.user.user_firstname}
                      </Text>
                      <Text color="#4299E1" fontSize="12px">
                        {userType(user.user.user_type)}
                      </Text>
                    </Box>
                    <Box ml="15px" />
                    <IoIosArrowDown size="20px" color="#718096" />
                  </Box>
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={Logout} icon={<AiOutlineApi />}>
                    Sair do sistema
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </HStack>

          <Stack mt="20px" cursor="pointer" p="10px 80px" direction="row" align="center">
            <Link to="/help">
              <a target="_blank" href="https://api.whatsapp.com/send?phone=5562999029155" rel="noreferrer">
                <GrNotification color="gray.300" />
              </a>
            </Link>
          </Stack>
        </HStack>
      </Center>
    </Box>
  )
}
