/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Text,
  Image,
  useDisclosure,
  FormErrorMessage,
  FormControl,
  FormLabel,
  SimpleGrid,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'

import { useFetch } from '../../../hooks/useFetch'
import { BiLeftArrow, BiSave, BiTrash, BiUpload } from 'react-icons/bi'
import { CheckIcon } from '@chakra-ui/icons'

interface FormProps {
  defaultValues?: any
}

interface FormInputsProps {
  name: string
  value: string
  placeholder?: string
  label: string
  yup: any
}

export default function SettingsForm({ defaultValues = {} }: FormProps) {
  const baseUrl = 'v1/prices_markup'
  const { requestApi, error, data } = useFetch(baseUrl)
  const { onOpen } = useDisclosure()
  const navigate = useNavigate()
  const yupschema: any = {}
  const defaultValuesSchema: any = {}
  const toast = useToast()

  const formInputs: FormInputsProps[] = [
    {
      name: 'mark_down',
      value: defaultValues.mark_down ?? '',
      placeholder: '',
      label: 'Mark Down',
      yup: Yup.number().required('Este campo é obrigatório!'),
    },
    {
      name: 'CC_cotation',
      value: defaultValues.CC_cotation ?? 0,
      placeholder: '',
      label: 'Cash Club Cotação',
      yup: Yup.number().required('Este campo é obrigatório!'),
    },
    {
      name: 'association_3y',
      value: defaultValues?.association_3y ?? 0,
      placeholder: '',
      label: 'Associação 3 anos',
      yup: Yup.number().required('Este campo é obrigatório!'),
    },
    {
      name: 'association_5y',
      value: defaultValues?.association_3y ?? 0,
      placeholder: '',
      label: 'Associação 5 anos',
      yup: Yup.number().required('Este campo é obrigatório!'),
    },
    {
      name: 'int_exchange',
      value: defaultValues?.int_exchange ?? 0,
      placeholder: '',
      label: 'Taxa de intercâmbio internacional',
      yup: Yup.number().required('Este campo é obrigatório!'),
    },
    {
      name: 'nat_exchage',
      value: defaultValues?.nat_exchage ?? 0,
      placeholder: '',
      label: 'Taxa de intercâmbio nacional',
      yup: Yup.number().required('Este campo é obrigatório!'),
    },
    {
      name: 'markup_currency',
      value: defaultValues?.markup_currency ?? 0,
      placeholder: '',
      label: 'Moeda de markup',
      yup: Yup.number().required('Este campo é obrigatório!'),
    },
  ]

  formInputs.forEach(object => {
    yupschema[object.name] = object.yup
    defaultValuesSchema[object.name] = object.value
  })
  const schema = Yup.object().shape(yupschema)

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  })

  const mark_down = formInputs.find(field => field.name === 'mark_down')
  const CC_cotation = formInputs.find(field => field.name === 'CC_cotation')
  const association_3y = formInputs.find(field => field.name === 'association_3y')
  const association_5y = formInputs.find(field => field.name === 'association_5y')
  const int_exchange = formInputs.find(field => field.name === 'int_exchange')
  const nat_exchage = formInputs.find(field => field.name === 'nat_exchage')
  const markup_currency = formInputs.find(field => field.name === 'markup_currency')

  const onSubmit = async (values: any) => {
    console.log('values', values)
    const response = await requestApi(
      defaultValues.mark_down ? 'put' : 'post',
      { ...values, image: _ },
      defaultValues.mark_down ? `${baseUrl}/0` : null,
    )

    if (response.status < 299) {
      toast({
        title: `Sucesso!`,
        position: 'top',
        status: 'success',
        isClosable: true,
      })
    } else {
      toast({
        title: `Ocorreu um erro ae executar a ação! ${error.message}`,
        position: 'top',
        status: 'error',
        isClosable: true,
      })
    }
  }

  console.log(errors, 'errors')

  useEffect(() => {
    if (defaultValues.mark_down) {
      reset(defaultValuesSchema)
    }
  }, [defaultValues])

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Flex flexDir="column" ml="4vw" w="73vw">
        <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
          <Tabs>
            <TabList>
              <Tab>
                <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
                  Parametros do sistema
                </Text>
              </Tab>
              <Tab>
                <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
                  Parametros de produtos
                </Text>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Flex flexDir="column" mb="40px">
                  <SimpleGrid columns={3} spacing={2}>
                    <Box maxW="md" mr="1">
                      <FormControl isInvalid={errors.mark_down && true}>
                        <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                          Mark Down
                        </FormLabel>
                        <InputGroup>
                          <Input id="mark_down" placeholder={mark_down?.label} isRequired {...register('mark_down')} />
                          <InputRightElement>
                            <Text>%</Text>
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>{`${errors.mark_down?.message}`}</FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Box maxW="md" mr="1">
                      <FormControl isInvalid={errors.markup_currency && true}>
                        <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                          Moeda de markup
                        </FormLabel>
                        <InputGroup>
                          <Input
                            id="markup_currency"
                            placeholder={markup_currency?.label}
                            isRequired
                            {...register('markup_currency')}
                          />
                          <InputRightElement>
                            <Text>%</Text>
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>{`${errors.markup_currency?.message}`}</FormErrorMessage>
                      </FormControl>
                    </Box>
                  </SimpleGrid>
                </Flex>
              </TabPanel>
              <TabPanel>
                <Flex alignItems={'center'} justifyContent={'space-between'}>
                  <Flex>
                    <Button
                      onClick={() => navigate('/products')}
                      rightIcon={<BiSave />}
                      colorScheme="red"
                      variant="solid"
                    >
                      Produtos
                    </Button>
                    <Box w={5} />
                    <Button
                      onClick={() => navigate('/smartweeks')}
                      rightIcon={<BiSave />}
                      colorScheme="red"
                      variant="solid"
                    >
                      SmartWeeks
                    </Button>
                  </Flex>
                </Flex>
                <SimpleGrid columns={3} spacing={2} mt={10}>
                  <Box maxW="md" mr="1">
                    <FormControl isInvalid={errors.CC_cotation && true}>
                      <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                        Cotação Cash Club
                      </FormLabel>
                      <Input
                        id="CC_cotation"
                        placeholder={CC_cotation?.label}
                        isRequired
                        {...register('CC_cotation')}
                      />
                      <FormErrorMessage>{`${errors.CC_cotation?.message}`}</FormErrorMessage>
                    </FormControl>
                  </Box>
                </SimpleGrid>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        <Box mb="200px" display="flex" justifyContent="space-between">
          <Button isLoading={isSubmitting} type="submit" rightIcon={<BiSave />} colorScheme="red" variant="solid">
            Salvar
          </Button>
        </Box>
      </Flex>
    </form>
  )
}
