import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Heading, Input, Select, Text, useToast } from '@chakra-ui/react'
import ResumeCard from '../../components/ResumeCard'
import ReactTable from '../../components/ReactTable'
import { ReturnButton } from '../../components/Buttons/ReturnButton'
import { BiEdit, BiFilter, BiUser, BiWallet } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { FiEye } from 'react-icons/fi'
import IUsers from '../../interfaces/IUsers'
import { api } from '../../services/api'
import { VscTrash, VscEdit, VscChecklist } from 'react-icons/vsc'
import { IconGeneralButton } from '../../components/Buttons/IconGeneralButton'
import ConfirmModal from '../../components/ConfirmModal'
import ConfirmModalExport from '../../components/ConfirmModal/ConfirmModalExport'
import { useFetch } from '../../hooks/useFetch'

const UsersCPT = () => {
  const baseUrl = '/users'
  const { requestApi, error } = useFetch('')
  const navigate = useNavigate()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [data, setData] = useState<IUsers[]>([])
  const [nome, setNome] = useState('')
  const [total, setTotal] = useState(0)
  const [isOpen, onOpen] = useState(true)
  const toast = useToast()

  useEffect(() => {
    getUsers()
  }, [pageNumber, perPage])

  const getUsers = async () => {
    try {
      const getUsers = await api.get(`v1/users-cpt?pageNumber=${pageNumber}&perPage=${perPage}&searchParam=${nome}`)

      if (getUsers.status === 200) {
        console.log('Aqui:::', getUsers.data.users.length)
        setData(getUsers.data.users)
        setPageSize(getUsers.data.paginateCount)
        setTotal(getUsers.data.count)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nome',
        columns: [
          {
            Header: 'Primeiro nome',
            accessor: 'user_firstname',
          },
          {
            Header: 'Sobrenome',
            accessor: 'user_lastname',
          },
        ],
      },
      {
        Header: 'Info',
        columns: [
          {
            Header: 'CPF',
            accessor: 'user_cpf',
          },
          {
            Header: 'Telefone',
            accessor: 'user_telephone',
          },
          {
            Header: 'Status',
            accessor: 'user_active',
            Cell: ({ row }: any) => {
              return row.original.user_active === '1' ? (
                <Text color="green.500">Ativo</Text>
              ) : (
                <Text color="red.500">Inativo</Text>
              )
            },
          },
          {
            Header: 'E-mail',
            accessor: 'email',
          },
          {
            Header: 'Ações',
            accessor: 'actions',
            Cell: ({ row }: any) => (
              <Flex align="center">{ConfirmModalExport(() => handleExportUser(row.original))}</Flex>
            ),
          },
        ],
      },
    ],
    [],
  )

  const handleExportUser = async (infos: any) => {
    const infos2 = {
      ...infos,
      user_type: 3,
      user_active: 1,
      password: '123456',
    }

    const response = await requestApi('post', infos2, `/v1/export`)

    if (response.status < 203) {
      toast({
        title: `Sucesso!`,
        position: 'top',
        status: 'success',
        isClosable: true,
      })
      navigate(`/users/${response.data.user_id}/edit`)
    } else {
      toast({
        title: `Ocorreu um erro ae executar a ação!`,
        position: 'top',
        status: 'error',
        isClosable: true,
      })
    }

    console.log('data', infos2)
  }

  return (
    <Flex flexDir="column" mt="7vh" ml="4vw" w="73vw">
      <Box display="flex" flexDir="column">
        <Box
          display="flex"
          alignContent="center"
          justifyItems="center"
          justifySelf="center"
          alignItems="center"
          flexDir="row"
          mb="20px"
        >
          <Button onClick={() => navigate('/users/create')} colorScheme="red" variant="solid">
            Adicionar usuário +
          </Button>
        </Box>
      </Box>
      <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
        <Text mb="20px" fontSize="24px" fontWeight="bold" color="#2D3748">
          Usuarios para aprovação de cadastro
        </Text>
        <Flex flexDir="column" mb="40px">
          <Box display="flex" flexDir="row">
            <Box width="40vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Busca (Nome, segundo nome, CPF, emial, ou telefone)
              </Text>
              <Input placeholder="Ex: Paulo" onChange={(e: any) => setNome(e.target.value)} />
            </Box>
            <Box display="flex" alignContent="end" alignItems="end">
              <Button leftIcon={<BiFilter />} colorScheme="red" onClick={getUsers} variant="solid">
                Filtrar
              </Button>
            </Box>
          </Box>
        </Flex>
        <Box>
          <Flex align="center" mb={5}>
            <ReturnButton previousPage="gamification" />

            <Heading as="h1" size="lg">
              Usuarios CPT
            </Heading>
          </Flex>

          <ReactTable
            columns={columns}
            pageNumber={pageNumber}
            data={data}
            totaPages={pageSize}
            onChange={(e: any) => {
              console.log('Akkkk:: ', e)
              setPageNumber(e.pageIndex + 1)
              setPerPage(e.sPageSize)
            }}
          />
        </Box>
      </Box>
    </Flex>
  )
}
export default UsersCPT
