import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SettingsForm from '../../components/forms/SettingsForm'

import { useFetch } from '../../hooks/useFetch'

const GeneralSettings = () => {
  const { requestApi, data } = useFetch(`v1/prices_markup/0`)

  useEffect(() => {
    requestApi('get')
  }, [])

  return <SettingsForm defaultValues={data ?? []} />
}
export default GeneralSettings
