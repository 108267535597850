// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Button,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { IconGeneralButton } from '../../components/Buttons/IconGeneralButton'

import { VscTrash } from 'react-icons/vsc'
// import { DeleteButton } from '../buttons/DeleteButton';

const ConfirmModalOwnership = (event: event) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <IconGeneralButton Icon={VscTrash} color="#1890FF" fontSize="25px" onClick={onOpen} />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmação de exclusão</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Text mb="1rem">Você tem certeza que deseja excluir este item?</Text>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Não
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                event()
                onClose()
              }}
            >
              Sim
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ConfirmModalOwnership
