import NotFoundPage from '../pages/404'
import LoginPage from '../pages/auth/login'
import RecoverPass from '../pages/auth/recover'

export const OpenRoutes = [
  {
    name: 'Not Found',
    path: '*',
    component: NotFoundPage,
  },
  {
    name: 'Login',
    path: '/login',
    component: LoginPage,
  },
  {
    name: 'Recuperar Senha',
    path: '/update_password',
    component: RecoverPass,
  },
]
