/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { api } from '../../../services/api'
import { Box, Button, Flex, Input, Text, FormLabel, SimpleGrid, Spinner } from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetch } from '../../../hooks/useFetch'
import { BiLeftArrow, BiTrash } from 'react-icons/bi'
import moment from 'moment'

interface FormProps {
  defaultValues?: any
  defaultValues2?: any
}

interface FormInputsProps {
  name: string
  value: string
  placeholder?: string
  label: string
  yup?: any
}

export default function ReservationForm({ defaultValues = {}, defaultValues2 = {} }: FormProps) {
  const newArray: any[] = []
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()

  const baseUrl = 'v1/reservations/cancel'
  const navigate = useNavigate()

  const onCancel = async (type: string) => {
    //requestApi('get', `${baseUrl}/${defaultValues.id}`)
    try {
      setLoading(true)
      const response = await api.delete(`${baseUrl}/${defaultValues.id}/${type}`)

      if (response.status < 299) {
        toast({
          title: `Sucesso!`,
          position: 'top',
          status: 'success',
          isClosable: true,
        })
        navigate('/reservations')
      } else {
        toast({
          title: `Ocorreu um erro na executar a ação!`,
          position: 'top',
          status: 'error',
          isClosable: true,
        })
      }
    } catch (error) {
      toast({
        title: `Ocorreu um erro na executar a ação!`,
        position: 'top',
        status: 'error',
        isClosable: true,
      })
      setLoading(false)
    }
    setLoading(false)
  }

  return (
    <form noValidate>
      <Flex flexDir="column" ml="4vw" w="73vw">
        <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
          <Flex flexDir="column" mb="40px">
            <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
              Informações da reserva
            </Text>
            <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
              Pessoas
            </Text>
            {defaultValues2.BookingDetail.Rooms[0].Paxs.map((e: any, key: number) => (
              <SimpleGrid key={key} columns={2} spacing={2}>
                <Box maxW="md" mr="1">
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Nome
                  </FormLabel>
                  <Input disabled id="Name" placeholder="Nome" value={`${e.Name} ${e.Surname}`} />
                </Box>
                <Box maxW="md" mr="1">
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    CPF
                  </FormLabel>
                  {e.Cpf !== '' || e.Cpf !== null || e.Cpf !== undefined ? (
                    <Input disabled id="Cpf" placeholder="Cpf" value={e.Cpf} />
                  ) : (
                    <div />
                  )}
                </Box>
              </SimpleGrid>
            ))}
            <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
              Informações da indentificação
            </Text>
            <SimpleGrid columns={2} spacing={2}>
              <Box maxW="xs" mr="1">
                <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                  BookingId
                </FormLabel>
                <Input
                  disabled
                  id="Cpf"
                  placeholder="Cpf"
                  style={{ width: '100%' }}
                  value={defaultValues2.BookingDetail.BookingNumber}
                />
              </Box>
              <Box maxW="xs" mr="1">
                <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                  ProviderID
                </FormLabel>
                <Input
                  disabled
                  id="Cpf"
                  placeholder="Cpf"
                  style={{ width: '100%' }}
                  value={defaultValues2.BookingDetail.Rooms[0].ProviderID}
                />
              </Box>
            </SimpleGrid>
            <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
              Informações da reserva
            </Text>
            <SimpleGrid columns={1} spacing={2}>
              <Box maxW="xs" mr="1">
                <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                  Descrição da reserva
                </FormLabel>
                <Input
                  disabled
                  id="Cpf"
                  placeholder="Cpf"
                  style={{ width: '100%' }}
                  value={defaultValues2.BookingDetail.Rooms[0].RoomDescription}
                />
              </Box>
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={2}>
              <Box maxW="md" mr="1">
                <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                  Adultos
                </FormLabel>
                <Input
                  disabled
                  id="Cpf"
                  placeholder="Cpf"
                  value={defaultValues2.BookingDetail.Rooms[0].NumberOfAdults}
                />
              </Box>
              <Box maxW="md" mr="1">
                <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                  Numero de noites
                </FormLabel>
                <Input
                  disabled
                  id="Cpf"
                  placeholder="Cpf"
                  value={defaultValues2.BookingDetail.Rooms[0].NumberOfNights}
                />
              </Box>
            </SimpleGrid>
            <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
              Informações do hotel
            </Text>
            <SimpleGrid columns={2} spacing={2}>
              <Box maxW="md" mr="1">
                <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                  Hotel
                </FormLabel>
                <Input disabled id="Cpf" placeholder="Cpf" value={defaultValues2.BookingDetail.Rooms[0].HotelName} />
              </Box>
              <Box maxW="md" mr="1">
                <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                  Endereço
                </FormLabel>
                <Input disabled id="Cpf" placeholder="Cpf" value={defaultValues2.BookingDetail.Rooms[0].HotelAddress} />
              </Box>
            </SimpleGrid>
            <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
              Datas
            </Text>
            <SimpleGrid columns={2} spacing={2}>
              <Box maxW="md" mr="1">
                <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                  Data de check-in
                </FormLabel>
                <Input
                  disabled
                  id="Cpf"
                  placeholder="Cpf"
                  value={moment(defaultValues2.BookingDetail.Rooms[0].CheckIn).format('DD/MM/YYYY')}
                />
              </Box>
              <Box maxW="md" mr="1">
                <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                  Data de check-out
                </FormLabel>
                <Input
                  disabled
                  id="Cpf"
                  placeholder="Cpf"
                  value={moment(defaultValues2.BookingDetail.Rooms[0].CheckOut).format('DD/MM/YYYY')}
                />
              </Box>
            </SimpleGrid>
            <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
              Valores e transação
            </Text>
            <SimpleGrid columns={3} spacing={2}>
              <Box maxW="md" mr="1">
                <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                  Valor da transação
                </FormLabel>
                <Input
                  disabled
                  id="Cpf"
                  placeholder="Cpf"
                  value={`R$ ${defaultValues2.BookingDetail.Rooms[0].NetPrice.Value.toString().replace('.', ',')}`}
                />
              </Box>
              <Box maxW="md" mr="1">
                <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                  Tid
                </FormLabel>
                <Input disabled id="Cpf" placeholder="Cpf" value={defaultValues.transaction.tid} />
              </Box>
              <Box maxW="md" mr="1">
                <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                  Vindi token
                </FormLabel>
                <Input disabled id="Cpf" placeholder="Cpf" value={defaultValues.transaction.vindi_tgw_id_token} />
              </Box>
            </SimpleGrid>
          </Flex>
        </Box>
        <Box mb="200px" display="flex" justifyContent="space-between">
          <Button
            onClick={() => navigate('/reservations')}
            leftIcon={<BiLeftArrow />}
            colorScheme="gray"
            variant="outline"
          >
            Voltar
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => onCancel('Reserva')}
            leftIcon={<BiTrash />}
            colorScheme="gray"
            variant="outline"
          >
            {isLoading && <Spinner size="sm" mr={2} />}
            Cancelar reserva
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => onCancel('Pagamento')}
            leftIcon={<BiTrash />}
            colorScheme="gray"
            variant="outline"
          >
            {isLoading && <Spinner size="sm" mr={2} />}
            Cancelar pegamento
          </Button>
        </Box>
      </Flex>
    </form>
  )
}
