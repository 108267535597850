import React from 'react'
import { Flex, Image, Box } from '@chakra-ui/react'
import MenuItem from '../../components/MenuItem'
// import { BiBuildingHouse, BiCalendar, BiCog, BiHome, BiRepeat, BiUserPlus } from 'react-icons/bi'
import { PrivateRoutes } from '../../routes/private.routes'

export default function SideBar() {
  // const toast = useToast()
  // console.log(toast)

  return (
    <Flex flexDir="column" h="100vh" w="20vw">
      <Image m="25px" src="/assets/logo.svg" h={50} />
      <Box mt="25px" display="flex" flexDir="column" justifyContent="center" alignItems="center">
        {PrivateRoutes.filter((item: any) => item.localeSidebar === 'top' && item.showSidebar).map(link => (
          <MenuItem
            // eslint-disable-next-line react/no-children-prop
            children={link.name}
            key={link.name}
            title={link.name}
            screen={link.path}
            href={link.path}
            icon={link.icon}
          />
        ))}
      </Box>
    </Flex>
  )
}
