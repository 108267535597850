/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Text,
  Image,
  useDisclosure,
  FormErrorMessage,
  FormControl,
  FormLabel,
  SimpleGrid,
} from '@chakra-ui/react'
import InputMask from 'react-input-mask'
import { useToast } from '@chakra-ui/react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'

import { useFetch } from '../../../hooks/useFetch'
import { BiLeftArrow, BiSave, BiTrash, BiUpload, BiRadar, BiStore } from 'react-icons/bi'
import ConfirmExport from './ConfirmExport'
import moment from 'moment'

interface UserFormProps {
  defaultValues?: any
}

interface FormInputsProps {
  name: string
  value: string
  placeholder?: string
  label: string
  yup: any
}

export default function UserForm({ defaultValues = {} }: UserFormProps) {
  const baseUrl = 'v1/users'
  const { requestApi, error, data } = useFetch(baseUrl)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { itemId } = useParams()
  const navigate = useNavigate()
  const [images, setImages] = useState<any>('')
  const [uploading, setUploading] = useState<any>(false)
  const yupschema: any = {}
  const defaultValuesSchema: any = {}
  const toast = useToast()
  const [idExport, setIdExport] = useState(null)
  const [valuesExports, setValuesExports] = useState({})
  const [cashClubs, setCashClubs] = useState<any>({})

  const formInputs: FormInputsProps[] = [
    {
      name: 'user_firstname',
      value: defaultValues.user_firstname ?? '',
      placeholder: '',
      label: 'Primeiro Nome',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'user_lastname',
      value: defaultValues.user_lastname ?? '',
      placeholder: '',
      label: 'Segundo nome',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'user_type',
      value: defaultValues?.user_type ?? 1,
      placeholder: '',
      label: 'Tipo de usuário',
      yup: Yup.number().required('Este campo é obrigatório!').min(1, 'Este campo é obrigatório'),
    },
    {
      name: 'user_active',
      value: defaultValues?.user_active ?? 1,
      placeholder: '',
      label: 'Ativo?',
      yup: Yup.number().required('Este campo é obrigatório!').min(0, 'Este campo é obrigatório'),
    },
    {
      name: 'user_cpf',
      value: defaultValues.user_cpf ?? '',
      placeholder: '',
      label: 'EX:. 000.000.000-00',
      yup: Yup.string()
        .required('Este campo é obrigatório!')
        .matches(
          /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/,
          'CPF inválido',
        ),
    },
    {
      name: 'user_telephone',
      value: defaultValues.user_telephone ?? '',
      placeholder: '',
      label: 'EX:. (00) 00000-0000',
      yup: Yup.string()
        .required('Este campo é obrigatório!')
        .matches(
          /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/,
          'Telefone inválido',
        ),
    },
    {
      name: 'email',
      value: defaultValues.email ?? '',
      placeholder: '',
      label: 'E-mail',
      yup: Yup.string().required('Este campo é obrigatório!').email('E-mail inválido'),
    },
    {
      name: 'user_address',
      value: defaultValues.user_address ?? '',
      placeholder: '',
      label: 'Endereço',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'user_address_zipcode',
      value: defaultValues.user_address_zipcode ?? '',
      placeholder: '',
      label: 'CEP',
      yup: Yup.string()
        .required('Este campo é obrigatório!')
        .matches(/(\d{2}[.]?\d{3})[-]?(\d{3})/, 'CEP inválido'),
    },
    {
      name: 'user_address_complement',
      value: defaultValues.user_address_complement ?? '',
      placeholder: '',
      label: 'Complemento',
      yup: Yup.string(),
    },
    {
      name: 'user_address_number',
      value: defaultValues.user_address_number ?? '',
      placeholder: '',
      label: 'Numero',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'user_address_neighborhood',
      value: defaultValues.user_address_neighborhood ?? '',
      placeholder: '',
      label: 'Bairro',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'user_address_city',
      value: defaultValues.user_address_city ?? '',
      placeholder: '',
      label: 'Cidade',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'user_address_state',
      value: defaultValues.user_address_state ?? '',
      placeholder: '',
      label: 'Estado',
      yup: Yup.string().required('Este campo é obrigatório!').min(2, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'user_address_country',
      value: defaultValues.user_address_country ?? '',
      placeholder: '',
      label: 'Pais',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'password',
      value: defaultValues.password ?? '123456',
      placeholder: '',
      label: 'Senha',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'user_country',
      value: defaultValues.user_country ?? '',
      placeholder: '',
      label: 'Nacionalidade',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'user_birthdate',
      value: moment(defaultValues.user_birthdate).format('DD/MM/YYYY') ?? '',
      placeholder: '',
      label: 'Data de aniversário',
      yup: Yup.string()
        .required('Este campo é obrigatório!')
        .matches(/^\d{2}[./-]\d{2}[./-]\d{4}$/, 'Data inválida'),
    },
  ]

  formInputs.forEach(object => {
    yupschema[object.name] = object.yup
    defaultValuesSchema[object.name] = object.value
  })
  const schema = Yup.object().shape(yupschema)

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  })

  const user_firstname = formInputs.find(field => field.name === 'user_firstname')
  const user_lastname = formInputs.find(field => field.name === 'user_lastname')
  const user_type = formInputs.find(field => field.name === 'user_type')
  const user_cpf = formInputs.find(field => field.name === 'user_cpf')
  const user_telephone = formInputs.find(field => field.name === 'user_telephone')
  const email = formInputs.find(field => field.name === 'email')
  const user_address = formInputs.find(field => field.name === 'user_address')
  const user_address_complement = formInputs.find(field => field.name === 'user_address_complement')
  const user_address_number = formInputs.find(field => field.name === 'user_address_number')
  const user_address_neighborhood = formInputs.find(field => field.name === 'user_address_neighborhood')
  const user_address_city = formInputs.find(field => field.name === 'user_address_city')
  const user_address_state = formInputs.find(field => field.name === 'user_address_state')
  const user_address_country = formInputs.find(field => field.name === 'user_address_country')
  const user_address_zipcode = formInputs.find(field => field.name === 'user_address_zipcode')
  const user_active = formInputs.find(field => field.name === 'user_active')
  const user_country = formInputs.find(field => field.name === 'user_country')
  const user_birthdate = formInputs.find(field => field.name === 'user_birthdate')

  const onSubmit = async (values: any) => {
    console.log('values', values)
    const response = await requestApi(
      defaultValues.user_firstname ? 'put' : 'post',
      {
        ...values,
        user_birthdate: moment(values.user_birthdate, 'DD/MM/YYYY'),
        user_cpf: values.user_cpf.replace(/\D/g, ''),
        user_telephone: values.user_telephone.replace(/\D/g, ''),
        image: _,
      },
      defaultValues.user_firstname ? `${baseUrl}/${itemId}` : null,
    )

    if (response.status < 203) {
      toast({
        title: `Sucesso!`,
        position: 'top',
        status: 'success',
        isClosable: true,
      })
      if (!error && images) {
        setUploading(true)
        const apiUrlId = itemId ?? response?.data?.user_id
        await requestApi('put', { file: images.toString() }, `${baseUrl}/${apiUrlId}/image`)
        setUploading(false)
        //onOpen()
      } else {
        //onOpen()
      }
      navigate('/users')
    } else if (response.status === 203) {
      setIdExport(response.data.message.id)
      setValuesExports(values)
      onOpen()
    } else {
      toast({
        title: `Ocorreu um erro ae executar a ação! ${error.message}`,
        position: 'top',
        status: 'error',
        isClosable: true,
      })
    }
  }

  console.log(errors, 'errors')

  const handleExport = async () => {
    const data = {
      ...valuesExports,
      user_id: idExport,
      user_type: 3,
      user_active: 1,
      password: '123456',
    }

    const response = await requestApi('post', data, `/v1/export`)

    if (response.status < 203) {
      toast({
        title: `Sucesso!`,
        position: 'top',
        status: 'success',
        isClosable: true,
      })
      navigate('/users')
    } else {
      toast({
        title: `Ocorreu um erro ae executar a ação!`,
        position: 'top',
        status: 'error',
        isClosable: true,
      })
    }

    console.log('data', data)
  }

  useEffect(() => {
    if (defaultValues.user_firstname) {
      reset(defaultValuesSchema)
    }
    const getInfos = async () => {
      if (itemId !== undefined) {
        await requestApi('get', null, `v1/cashclubs/pointsbalance/${itemId}`)
      }
    }
    getInfos()
  }, [defaultValues])
  const dataGroups = [
    { id: 0, name: 'Selecione' },
    { id: 1, name: 'Administrador' },
    { id: 2, name: 'Colaborador' },
    { id: 3, name: 'Cliente' },
    { id: 4, name: 'Administrador Hotel' },
  ]
  const dataActive = [
    { id: 0, name: 'Inativo' },
    { id: 1, name: 'Ativo' },
  ]
  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Flex flexDir="column" ml="4vw" w="73vw">
        <ConfirmExport isOpen={isOpen} event={handleExport} onOpen={onOpen} onClose={onClose} />
        <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
          <Text mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
            Foto
          </Text>
          <Image w="250px" mb={7} src="/assets/noImg.png" />
          <Box display="flex" flexDir="row" mb="20px">
            <Button colorScheme="gray" mr="20px" rightIcon={<BiTrash />} variant="outline">
              Excluir foto
            </Button>
            <Button colorScheme="gray" mr="20px" rightIcon={<BiUpload />} variant="outline">
              Upload de foto
            </Button>
            <Button
              onClick={() => navigate(`/balance/${itemId}`)}
              colorScheme="red"
              mr="20px"
              rightIcon={<BiRadar />}
              variant="solid"
            >
              Extrato de pontos
            </Button>

            <Button
              onClick={() => navigate(`/ownership/${itemId}`)}
              colorScheme="red"
              rightIcon={<BiStore />}
              variant="solid"
            >
              Propriedades
            </Button>
          </Box>
          <Text mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
            Informações básicas
          </Text>
          <Flex flexDir="column" mb="40px">
            <SimpleGrid columns={3} spacing={1}>
              <Box maxW="md" mr="1">
                <FormControl>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Saldo de CashClubs Nacionais
                  </FormLabel>
                  <Input id="user_firstnam" placeholder="0" value={data?.saldoN ?? 0} isRequired disabled />
                </FormControl>
              </Box>
              <Box maxW="md" ml="1">
                <FormControl>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Saldo de CashClubs Internacionais
                  </FormLabel>
                  <Input id="user_firstnam" placeholder="0" value={data?.saldoI ?? 0} isRequired disabled />
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.user_active && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    {user_active?.label}
                  </FormLabel>
                  <Select isRequired {...register('user_active')}>
                    {dataActive.map((group, index) => (
                      <option key={index} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{`${errors.user_type?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
            </SimpleGrid>
            <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
              Informações pessoais e de contato
            </Text>
            <SimpleGrid columns={3} spacing={1}>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.user_firstname && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Primeiro nome
                  </FormLabel>
                  <Input
                    id="user_firstname"
                    placeholder={user_firstname?.label}
                    isRequired
                    {...register('user_firstname')}
                  />
                  <FormErrorMessage>{`${errors.user_firstname?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.user_lastname && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Segundo nome
                  </FormLabel>
                  <Input
                    id="user_lastname"
                    placeholder={user_lastname?.label}
                    isRequired
                    {...register('user_lastname')}
                  />
                  <FormErrorMessage>{`${errors.user_lastname?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.user_type && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    {user_type?.label}
                  </FormLabel>
                  <Select isRequired {...register('user_type')}>
                    {dataGroups.map((group, index) => (
                      <option key={index} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{`${errors.user_type?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
            </SimpleGrid>
            <SimpleGrid columns={3} spacing={1} style={{ marginTop: 15 }}>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.user_country && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Nascionalidade
                  </FormLabel>
                  <Input id="user_country" placeholder={user_country?.label} isRequired {...register('user_country')} />
                  <FormErrorMessage>{`${errors.user_country?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.user_cpf && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    CPF
                  </FormLabel>
                  <Input
                    as={InputMask}
                    mask={'999.999.999-99'}
                    id="user_cpf"
                    placeholder={user_cpf?.label}
                    isRequired
                    {...register('user_cpf')}
                  />
                  <FormErrorMessage>{`${errors.user_cpf?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.start_at && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Data de aniversário
                  </FormLabel>
                  <Input
                    mask="99/99/9999"
                    as={InputMask}
                    id="user_birthdate"
                    placeholder={user_birthdate?.label}
                    isRequired
                    {...register('user_birthdate')}
                  />
                  <FormErrorMessage>{`${errors.user_birthdate?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.user_telephone && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Telefone
                  </FormLabel>
                  <Input
                    as={InputMask}
                    mask={'+99 (99) 99999-9999'}
                    id="user_telephone"
                    placeholder={user_telephone?.label}
                    isRequired
                    {...register('user_telephone')}
                  />
                  <FormErrorMessage>{`${errors.user_telephone?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.email && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    E-mail
                  </FormLabel>
                  <Input id="email" placeholder={email?.label} isRequired {...register('email')} />
                  <FormErrorMessage>{`${errors.email?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
            </SimpleGrid>
            <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
              Informações de endereço
            </Text>
            <SimpleGrid columns={4} spacing={2}>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.user_address && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Endereço
                  </FormLabel>
                  <Input id="user_address" placeholder={user_address?.label} isRequired {...register('user_address')} />
                  <FormErrorMessage>{`${errors.user_address?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.user_address_complement && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Complemento
                  </FormLabel>
                  <Input
                    id="user_address_complement"
                    placeholder={user_address_complement?.label}
                    isRequired
                    {...register('user_address_complement')}
                  />
                  <FormErrorMessage>{`${errors.user_address_complement?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.user_address_neighborhood && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Bairro
                  </FormLabel>
                  <Input
                    id="user_address_neighborhood"
                    placeholder={user_address_neighborhood?.label}
                    isRequired
                    {...register('user_address_neighborhood')}
                  />
                  <FormErrorMessage>{`${errors.user_address_neighborhood?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.user_address_zipcode && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    CEP
                  </FormLabel>
                  <Input
                    as={InputMask}
                    mask={'99.999-999'}
                    id="user_address_zipcode"
                    placeholder={user_address_zipcode?.label}
                    isRequired
                    {...register('user_address_zipcode')}
                  />
                  <FormErrorMessage>{`${errors.user_address_zipcode?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="sm" mr="1">
                <FormControl isInvalid={errors.user_address_number && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Numero
                  </FormLabel>
                  <Input
                    id="user_address_number"
                    placeholder={user_address_number?.label}
                    isRequired
                    {...register('user_address_number')}
                  />
                  <FormErrorMessage>{`${errors.user_address_number?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="sm" mr="1">
                <FormControl isInvalid={errors.user_address_city && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Cidade
                  </FormLabel>
                  <Input
                    id="user_address_city"
                    placeholder={user_address_city?.label}
                    isRequired
                    {...register('user_address_city')}
                  />
                  <FormErrorMessage>{`${errors.user_address_city?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="sm" mr="1">
                <FormControl isInvalid={errors.user_address_state && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Estado
                  </FormLabel>
                  <Input
                    id="user_address_state"
                    placeholder={user_address_state?.label}
                    isRequired
                    {...register('user_address_state')}
                  />
                  <FormErrorMessage>{`${errors.user_address_state?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="sm" mr="1">
                <FormControl isInvalid={errors.user_address_country && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    País
                  </FormLabel>
                  <Input
                    id="user_address_country"
                    placeholder={user_address_country?.label}
                    isRequired
                    {...register('user_address_country')}
                  />
                  <FormErrorMessage>{`${errors.user_address_country?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
            </SimpleGrid>
          </Flex>
        </Box>
        <Box mb="200px" display="flex" justifyContent="space-between">
          <Button onClick={() => navigate('/users')} leftIcon={<BiLeftArrow />} colorScheme="gray" variant="outline">
            Cancelar e voltar
          </Button>
          <Button
            isLoading={isSubmitting || uploading}
            type="submit"
            rightIcon={<BiSave />}
            colorScheme="red"
            variant="solid"
          >
            Salvar
          </Button>
        </Box>
      </Flex>
    </form>
  )
}
