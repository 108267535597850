import React from 'react'
import { useTranslation } from 'react-i18next'

export default function NotFoundPage() {
  const { t } = useTranslation()
  return (
    <div className="App">
      <header className="App-header">
        <p>Pagina não encontrada</p>
        <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
          {t('button.sign_in')}
        </a>
      </header>
    </div>
  )
}
