/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Text,
  Image,
  useDisclosure,
  FormErrorMessage,
  FormControl,
  FormLabel,
  SimpleGrid,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
} from '@chakra-ui/react'
import { CUIAutoComplete } from 'chakra-ui-autocomplete'
import { useToast } from '@chakra-ui/react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'
import { useFetch } from '../../../hooks/useFetch'
import { BiLeftArrow, BiRadar, BiSave, BiTrash, BiUpload } from 'react-icons/bi'
import { api } from '../../../services/api'
import { STORAGE_KEYS } from '../../../constants/Config'
import moment from 'moment'

interface FormProps {
  defaultValues?: any
  defaultValues2?: any
  users: any
  enterprises: any
  habitational_unity: any
}

interface FormInputsProps {
  name: string
  value: string
  placeholder?: string
  label: string
  yup?: any
}

export default function ExchangesForm({
  defaultValues = {},
  defaultValues2 = {},
  users = [],
  enterprises = [],
  habitational_unity = [],
}: FormProps) {
  const newArray: any[] = []
  users.map((user: { user_firstname: any; user_id: any }) => {
    newArray.push({ label: user.user_firstname, value: user.user_id })
  })

  const newArray2: any[] = []
  enterprises.map((enterprise: { enterprise_name: any; enterprise_id: any }) => {
    newArray2.push({ label: enterprise.enterprise_name, value: enterprise.enterprise_id })
  })

  const newArray3: any[] = []
  habitational_unity.map((hh: { room_type: any; habitational_unity_id: any; pax: any }) => {
    newArray3.push({ label: `${hh.room_type} ${hh.pax} PAX`, value: hh.habitational_unity_id })
  })

  const baseUrl = 'v1/exchanges'
  const { requestApi, error, data } = useFetch(baseUrl)
  const [optionsUsers, setOptionsUsers] = useState<any>(newArray)
  const [optionsEnterprises, setOptionsEnterprises] = useState<any>([])
  const { onOpen } = useDisclosure()
  const { itemId } = useParams()
  const navigate = useNavigate()
  const yupschema: any = {}
  const defaultValuesSchema: any = {}
  const toast = useToast()
  const user = JSON.parse(localStorage.getItem(STORAGE_KEYS.AUTH as string) as string)
  console.log('wwwwwww:: ', defaultValues)

  const formInputs: FormInputsProps[] = [
    {
      name: 'id_user',
      value: defaultValues.id_user ?? '',
      placeholder: '',
      label: 'Usuário',
      yup: Yup.number().required('Este campo é obrigatório!'),
    },
    {
      name: 'id_cessionario',
      value: defaultValues.id_cessionario ?? '',
      placeholder: '',
      label: 'Empreedimento',
      yup: Yup.number().required('Este campo é obrigatório!'),
    },
    {
      name: 'qtde_quartos',
      value: defaultValues?.qtde_quartos ?? '',
      placeholder: '',
      label: 'Quantidade de quartos',
      yup: Yup.number(),
    },
    {
      name: 'data_checkin',
      value: defaultValues?.data_checkin ? moment(defaultValues?.data_checkin, 'YYYY-MM-DD').format('DD/MM/YYYY') : '',
      placeholder: '',
      label: 'Data chechin',
      yup: Yup.string().required('Este campo é obrigatório!').min(0, 'Este campo é obrigatório'),
    },
    {
      name: 'data_checkout',
      value: defaultValues?.data_checkout
        ? moment(defaultValues?.data_checkout, 'YYYY-MM-DD').format('DD/MM/YYYY')
        : '',
      placeholder: '',
      label: 'Data checkout',
      yup: Yup.string().required('Este campo é obrigatório!').min(0, 'Este campo é obrigatório'),
    },
    {
      name: 'uh',
      value: defaultValues.uh ?? '',
      placeholder: '',
      label: 'Unidade habitacional',
      yup: Yup.number().required('Este campo é obrigatório!'),
    },
    {
      name: 'tipo_intercambio',
      value: defaultValues.tipo_intercambio ?? '',
      placeholder: '',
      label: 'Tipo de intercâmbio',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'qtde_pontos_ofertados',
      value: defaultValues.qtde_pontos_ofertados ?? '',
      placeholder: '',
      label: 'Quantidade de pontos ofertados',
      yup: Yup.number().required('Este campo é obrigatório!'),
    },
    {
      name: 'valor_intercambio',
      value: defaultValues.valor_intercambio ?? '',
      placeholder: '',
      label: 'Valor do intercâmbio',
      yup: Yup.number(),
    },
    {
      name: 'qtde_dias_vendidos',
      value: defaultValues.qtde_dias_vendidos ?? '',
      placeholder: 'Quantida de dias vendidos',
      label: 'Quantida de dias vendidos',
      yup: Yup.number(),
    },
    {
      name: 'data_venda',
      value: defaultValues.data_venda ? moment(defaultValues?.data_venda, 'YYYY-MM-DD').format('DD/MM/YYYY') : '',
      placeholder: '',
      label: 'Data da venda',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'preco_sugerido',
      value: defaultValues.preco_sugerido ?? '',
      placeholder: '',
      label: 'Preço sugerido',
      yup: Yup.number().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'dias_vendidos',
      value: defaultValues.dias_vendidos ?? '',
      placeholder: '',
      label: 'Dias vendidos',
      yup: Yup.number(),
    },
  ]

  formInputs.forEach(object => {
    yupschema[object.name] = object.yup
    defaultValuesSchema[object.name] = object.value
  })
  const schema = Yup.object().shape(yupschema)

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  })

  const id_user = formInputs.find(field => field.name === 'id_user')
  const id_cessionario = formInputs.find(field => field.name === 'id_cessionario')
  const qtde_quartos = formInputs.find(field => field.name === 'qtde_quartos')
  const data_checkin = formInputs.find(field => field.name === 'data_checkin')
  const data_checkout = formInputs.find(field => field.name === 'data_checkout')
  const uh = formInputs.find(field => field.name === 'uh')
  const tipo_intercambio = formInputs.find(field => field.name === 'tipo_intercambio')
  const qtde_pontos_ofertados = formInputs.find(field => field.name === 'qtde_pontos_ofertados')
  const valor_intercambio = formInputs.find(field => field.name === 'valor_intercambio')
  const qtde_dias_vendidos = formInputs.find(field => field.name === 'qtde_dias_vendidos')
  const data_venda = formInputs.find(field => field.name === 'data_venda')
  const preco_sugerido = formInputs.find(field => field.name === 'preco_sugerido')
  const dias_vendidos = formInputs.find(field => field.name === 'dias_vendidos')

  const onSubmit = async (values: any) => {
    const vv: any = {
      ...values,
      data_checkin: moment(values.data_checkin, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      hotel: enterprises.find((e: any) => e.enterprise_id === values.id_cessionario)?.enterprise_name,
      user_name: users.find((e: any) => e.user_id === values.id_user)?.user_firstname,
      data_checkout: moment(values.data_checkout, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      data_venda: moment(values.data_venda, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    }
    console.log('values', vv)
    const response = await requestApi(
      defaultValues.id_user ? 'put' : 'post',
      vv,
      defaultValues.id_user ? `${baseUrl}/${itemId}` : null,
    )

    if (response.status < 299) {
      toast({
        title: `Sucesso!`,
        position: 'top',
        status: 'success',
        isClosable: true,
      })
      navigate('/exchanges')
    } else {
      toast({
        title: `Ocorreu um erro ae executar a ação! ${error.message}`,
        position: 'top',
        status: 'error',
        isClosable: true,
      })
    }
  }

  console.log(errors, 'errors')

  const dataActive = [
    { id: 'N', name: 'Nacional' },
    { id: 'I', name: 'Internacional' },
  ]

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Flex flexDir="column" ml="4vw" w="73vw">
        <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
          <Flex flexDir="column" mb="40px">
            <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
              Informações básicas
            </Text>
            <SimpleGrid columns={3} spacing={2}>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.id_user && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Usuário
                  </FormLabel>
                  <Select isRequired {...register('id_user')} placeholder="Selecione o usuário">
                    {newArray.map((group: any, index: any) => (
                      <option key={index} value={group.value}>
                        {group.label}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{`${errors.id_user?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.id_cessionario && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Empreedimento
                  </FormLabel>
                  <Select isRequired {...register('id_cessionario')} placeholder="Selecione um empreedimento">
                    {newArray2.map((group: any, index: any) => (
                      <option key={index} value={group.value}>
                        {group.label}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{`${errors.id_cessionario?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.qtde_quartos && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Numero de quartos
                  </FormLabel>
                  <Input
                    id="qtde_quartos"
                    type={'number'}
                    placeholder={qtde_quartos?.label}
                    isRequired
                    {...register('qtde_quartos')}
                  />
                  <FormErrorMessage>{`${errors.qtde_quartos?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.data_checkin && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Data de checkin
                  </FormLabel>
                  <Input
                    as={InputMask}
                    mask={'99/99/9999'}
                    id="data_checkin"
                    placeholder={data_checkin?.label}
                    isRequired
                    {...register('data_checkin')}
                  />
                  <FormErrorMessage>{`${errors.data_checkin?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.data_checkout && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Data de checkout
                  </FormLabel>
                  <Input
                    as={InputMask}
                    mask={'99/99/9999'}
                    id="data_checkout"
                    placeholder={data_checkout?.label}
                    isRequired
                    {...register('data_checkout')}
                  />
                  <FormErrorMessage>{`${errors.data_checkout?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.uh && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    {uh?.label}
                  </FormLabel>
                  <Select isRequired {...register('uh')} placeholder="Selecione um UH">
                    {newArray3.map((group: any, index: any) => (
                      <option key={index} value={group.value}>
                        {group.label}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{`${errors.uh?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.tipo_intercambio && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    {tipo_intercambio?.label}
                  </FormLabel>
                  <Select isRequired {...register('tipo_intercambio')}>
                    {dataActive.map((group, index) => (
                      <option key={index} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{`${errors.tipo_intercambio?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.qtde_pontos_ofertados && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    {qtde_pontos_ofertados?.label}
                  </FormLabel>
                  <Input
                    id="qtde_pontos_ofertados"
                    type={'number'}
                    placeholder={qtde_pontos_ofertados?.label}
                    isRequired
                    {...register('qtde_pontos_ofertados')}
                  />
                  <FormErrorMessage>{`${errors.qtde_pontos_ofertados?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.valor_intercambio && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    {valor_intercambio?.label}
                  </FormLabel>
                  <Input
                    id="valor_intercambio"
                    type={'number'}
                    placeholder={valor_intercambio?.label}
                    isRequired
                    {...register('valor_intercambio')}
                  />
                  <FormErrorMessage>{`${errors.valor_intercambio?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.data_venda && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    {data_venda?.label}
                  </FormLabel>
                  <Input
                    as={InputMask}
                    mask={'99/99/9999'}
                    id="data_venda"
                    placeholder={data_venda?.label}
                    isRequired
                    {...register('data_venda')}
                  />
                  <FormErrorMessage>{`${errors.data_venda?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.preco_sugerido && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    {preco_sugerido?.label}
                  </FormLabel>
                  <Input
                    id="preco_sugerido"
                    placeholder={preco_sugerido?.label}
                    type={'number'}
                    isRequired
                    {...register('preco_sugerido')}
                  />
                  <FormErrorMessage>{`${errors.preco_sugerido?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.qtde_dias_vendidos && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    {qtde_dias_vendidos?.label}
                  </FormLabel>
                  <Input
                    id="qtde_dias_vendidos"
                    type={'number'}
                    placeholder={qtde_dias_vendidos?.label}
                    isRequired
                    {...register('qtde_dias_vendidos')}
                  />
                  <FormErrorMessage>{`${errors.qtde_dias_vendidos?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
            </SimpleGrid>
          </Flex>
        </Box>
        <Box mb="200px" display="flex" justifyContent="space-between">
          <Button
            onClick={() => navigate('/exchanges')}
            leftIcon={<BiLeftArrow />}
            colorScheme="gray"
            variant="outline"
          >
            Cancelar e voltar
          </Button>
          <Button isLoading={isSubmitting} type="submit" rightIcon={<BiSave />} colorScheme="red" variant="solid">
            Salvar
          </Button>
        </Box>
      </Flex>
    </form>
  )
}
