import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import React, { StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'

import App from './App'
import { SettingsProvider } from './providers/settings'
import { theme } from './theme'

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container as HTMLElement)

root.render(
  <StrictMode>
    <SettingsProvider>
      <ChakraProvider theme={theme}>
        <ColorModeScript />
        <App />
      </ChakraProvider>
    </SettingsProvider>
  </StrictMode>,
)
