import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { Box, Button, Flex, Heading, Input, Select, Text, useToast } from '@chakra-ui/react'
import ResumeCard from '../../components/ResumeCard'
import ReactTable from '../../components/ReactTable'
import { ReturnButton } from '../../components/Buttons/ReturnButton'
import { BiEdit, BiFilter, BiUser, BiWallet } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { FiEye } from 'react-icons/fi'
import IUsers from '../../interfaces/IUsers'
import { api } from '../../services/api'
import { VscTrash, VscEdit, VscChecklist } from 'react-icons/vsc'
import { IconGeneralButton } from '../../components/Buttons/IconGeneralButton'
import ConfirmModal from '../../components/ConfirmModal'

const Users = () => {
  const baseUrl = '/users'
  const navigate = useNavigate()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [data, setData] = useState<IUsers[]>([])
  const [total, setTotal] = useState(0)
  const [isOpen, onOpen] = useState(true)
  const toast = useToast()

  const [user_id, setUser_id] = useState('')
  const [user_firstname, setUser_firstname] = useState('')
  const [user_lastname, setUser_lastname] = useState('')
  const [email, setEmail] = useState('')
  const [user_cpf, setUser_cpf] = useState('')
  const [user_type, setUser_type] = useState('-1')
  const [user_active, setUser_active] = useState('-1')

  useEffect(() => {
    getUsers()
  }, [pageNumber, perPage])

  const getUsers = async () => {
    try {
      const getUsers = await api.get(`v1/users?pageNumber=${pageNumber}&perPage=${perPage}`)

      if (getUsers.status === 200) {
        console.log('Aqui:::', getUsers.data.users.length)
        setData(getUsers.data.users)
        setPageSize(getUsers.data.paginateCount)
        setTotal(getUsers.data.count)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleFilter = async () => {
    try {
      setData([])
      const getUsers = await api.post(`v1/users/filter?pageNumber=${pageNumber}&perPage=${perPage}`, {
        user_id,
        user_firstname,
        user_lastname,
        email,
        user_cpf,
        user_type,
        user_active,
      })

      if (getUsers.status === 200) {
        console.log('Aqui:::', getUsers.data.users.length)
        setData(getUsers.data.users)
        setPageSize(getUsers.data.paginateCount)
        setTotal(getUsers.data.count)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nome',
        columns: [
          {
            Header: 'Primeiro nome',
            accessor: 'user_firstname',
          },
          {
            Header: 'Sobrenome',
            accessor: 'user_lastname',
          },
        ],
      },
      {
        Header: 'Info',
        columns: [
          {
            Header: 'CPF',
            accessor: 'user_cpf',
          },
          {
            Header: 'Telefone',
            accessor: 'user_telephone',
          },
          {
            Header: 'Status',
            accessor: 'user_active',
            Cell: ({ row }: any) => {
              return row.original.user_active === '1' ? (
                <Text color="green.500">Ativo</Text>
              ) : (
                <Text color="red.500">Inativo</Text>
              )
            },
          },
          {
            Header: 'E-mail',
            accessor: 'email',
            Cell: ({ row }: any) => (
              <Box minWidth="50px">
                <Text>{row.original.email}</Text>
              </Box>
            ),
          },
          {
            Header: 'Ações',
            accessor: 'actions',
            Cell: ({ row }: any) => (
              <Flex align="center">
                <IconGeneralButton
                  Icon={VscEdit}
                  color="#1890FF"
                  fontSize="25px"
                  onClick={() => {
                    console.log('Editar', row.original)
                    navigate(`${row.original.user_id}/edit`)
                  }}
                />
                {ConfirmModal(() =>
                  toast({
                    title: `Usuário sem permisão para excluir o usuário ${row.original.user_firstname}`,
                    position: 'top',
                    status: 'error',
                    isClosable: true,
                  }),
                )}
              </Flex>
            ),
          },
        ],
      },
    ],
    [],
  )

  return (
    <Flex flexDir="column" mt="7vh" ml="4vw" w="73vw">
      <Box display="flex" flexDir="column">
        <Box
          display="flex"
          alignContent="center"
          justifyItems="center"
          justifySelf="center"
          alignItems="center"
          flexDir="row"
          mb="20px"
        >
          <ResumeCard title="Todos usuários" icon={<BiUser color="#fff" />} subititle={`${total}`} />
          <Box w="80px" />
          <ResumeCard
            title="Clientes"
            icon={<BiWallet color="#fff" />}
            subititle={data.filter(e => e.user_type === '3').length.toString()}
          />
          <Box w="80px" />
          <Button onClick={() => navigate('/users/create')} colorScheme="red" variant="solid">
            Adicionar usuário +
          </Button>
          <Box w="20px" />
          <Button onClick={() => navigate('/users-cpt')} variant="solid">
            Outros usuários
          </Button>
        </Box>
      </Box>
      <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
        <Text mb="20px" fontSize="24px" fontWeight="bold" color="#2D3748">
          Usuários
        </Text>
        <Flex flexDir="column" mb="40px">
          <Box display="flex" flexDir="row">
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                ID
              </Text>
              <Input placeholder="Ex: 589" value={user_id} onChange={e => setUser_id(e.target.value)} />
            </Box>
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Nome
              </Text>
              <Input placeholder="Ex: Paulo" value={user_firstname} onChange={e => setUser_firstname(e.target.value)} />
            </Box>
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Sobrenome
              </Text>
              <Input placeholder="Ex: Mateus" value={user_lastname} onChange={e => setUser_lastname(e.target.value)} />
            </Box>
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Email
              </Text>
              <Input
                type="email"
                placeholder="Ex: endereço@email.com"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Box>
          </Box>
          <Box mt="20px" display="flex" flexDir="row">
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Status
              </Text>
              <Select value={user_active} onChange={e => setUser_active(e.target.value)}>
                <option value="-1">Todos</option>
                <option value="1">Ativo</option>
                <option value="0">Inativo</option>
              </Select>
            </Box>
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Grupo
              </Text>
              <Select value={user_type} onChange={e => setUser_type(e.target.value)}>
                <option value="-1">Todos</option>
                <option value="1">Administrador</option>
                <option value="2">Colaborador</option>
                <option value="3">Cliente</option>
              </Select>
            </Box>
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                CPF
              </Text>
              <Input
                as={InputMask}
                mask={'999.999.999-99'}
                placeholder="Ex:. 123.456.789-00"
                value={user_cpf}
                onChange={e => setUser_cpf(e.target.value)}
              />
            </Box>
            <Box display="flex" alignContent="end" alignItems="end">
              <Button leftIcon={<BiFilter />} onClick={handleFilter} colorScheme="red" variant="solid">
                Filtrar
              </Button>
            </Box>
          </Box>
        </Flex>
        <Box>
          <Flex align="center" mb={5}>
            <ReturnButton previousPage="gamification" />

            <Heading as="h1" size="lg">
              Lista de usuários
            </Heading>
          </Flex>

          <ReactTable
            columns={columns}
            pageNumber={pageNumber}
            data={data}
            totaPages={pageSize}
            onChange={(e: any) => {
              console.log('Akkkk:: ', e)
              setPageNumber(e.pageIndex + 1)
              setPerPage(e.sPageSize)
            }}
          />
        </Box>
      </Box>
    </Flex>
  )
}
export default Users
