/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React from 'react'
import { useTable, usePagination } from 'react-table'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  IconButton,
  Text,
  Tooltip,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Box,
} from '@chakra-ui/react'
// eslint-disable-next-line import/no-unresolved
import { ArrowRightIcon, ArrowLeftIcon, ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons'

function ReactTable({ columns, data, totaPages, pageNumber, onChange }) {
  // Use the state and functions returned from useTable to build your UI
  const [pageIndex, setPageIndex] = React.useState(0)
  const [canNextPage, setCanNextPage] = React.useState(true)
  const [canPreviousPage, setCanPreviousPage] = React.useState(true)
  const [sPageSize, setPageSize] = React.useState(10)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    pageOptions,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      totaPages,
    },
    usePagination,
  )

  React.useEffect(() => {
    const temp = totaPages - 1
    console.log('temp', temp)
    console.log('temp', temp)
    setCanNextPage(pageIndex < temp)
    setCanPreviousPage(pageIndex > 0)
  }, [pageIndex, totaPages])

  onChange({
    pageIndex,
    pageSize,
    pageOptions,
    pageNumber,
    sPageSize,
  })
  // Render the UI for your table
  return (
    <Box style={{ marginBottom: 100, width: '100%' }} overflowX="auto">
      <Table variant="striped" size="sm" {...getTableProps()}>
        <Thead>
          {headerGroups.map(headerGroup => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>

      <Flex justifyContent="space-between" m={4} alignItems="center">
        <Flex>
          <Tooltip label="First Page">
            <IconButton
              onClick={() => setPageIndex(0)}
              isDisabled={!canPreviousPage}
              icon={<ArrowLeftIcon h={3} w={3} />}
              mr={4}
            />
          </Tooltip>
          <Tooltip label="Previous Page">
            <IconButton
              onClick={() => setPageIndex(pageIndex - 1)}
              isDisabled={!canPreviousPage}
              icon={<ChevronLeftIcon h={6} w={6} />}
            />
          </Tooltip>
        </Flex>

        <Flex alignItems="center">
          <Text flexShrink="0" mr={8}>
            Página{' '}
            <Text fontWeight="bold" as="span">
              {pageNumber}
            </Text>{' '}
            de{' '}
            <Text fontWeight="bold" as="span">
              {Math.ceil(totaPages)}
            </Text>
          </Text>
          <Text flexShrink="0">Ir para página:</Text>{' '}
          <NumberInput
            ml={2}
            mr={8}
            w={28}
            min={1}
            max={totaPages}
            onChange={value => {
              const page = value ? value - 1 : 0
              setPageIndex(page)
            }}
            defaultValue={pageIndex + 1}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Select
            w={32}
            value={sPageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </Select>
        </Flex>

        <Flex>
          <Tooltip label="Próxima Página">
            <IconButton
              onClick={() => setPageIndex(pageIndex + 1)}
              isDisabled={!canNextPage}
              icon={<ChevronRightIcon h={6} w={6} />}
            />
          </Tooltip>
          <Tooltip label="Última Página">
            <IconButton
              onClick={() => setPageIndex(totaPages - 1)}
              isDisabled={!canNextPage}
              icon={<ArrowRightIcon h={3} w={3} />}
              ml={4}
            />
          </Tooltip>
        </Flex>
      </Flex>
    </Box>
  )
}

export default ReactTable
