import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'

interface DasboardCardProps {
  icon: any
  title: string
  subititle: string
}

const ResumeCard = (props: DasboardCardProps) => {
  return (
    <Flex flexDir="row">
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box
          cursor="pointer"
          display="flex"
          flexDir="row"
          p="20px"
          justifyContent="space-between"
          alignItems="center"
          justifySelf="center"
          borderRadius="10px"
          boxShadow="base"
          bg="white"
          h="80px"
          w="14vw"
        >
          <Box>
            <Text color="#A0AEC0" fontWeight="bold" fontSize="14px">
              {props.title}
            </Text>

            <Text color="#2D3748" fontWeight="bold" fontSize="16px">
              {props.subititle}
            </Text>
          </Box>
          <Box
            display="flex"
            alignSelf="center"
            alignContent="center"
            alignItems="center"
            justifySelf="center"
            justifyContent="center"
            justifyItems="center"
            borderRadius="10px"
            ml="10px"
            w="30px"
            h="30px"
            bg="#000"
          >
            {props.icon}
          </Box>
        </Box>
      </Box>
    </Flex>
  )
}
export default ResumeCard
