/* eslint-disable react/no-children-prop */
import React from 'react'
import { useColorModeValue } from '@chakra-ui/react'
import Select from 'react-select'

export const SelectFilter = ({
  onChange,
  placeholder,
  options,
  setValue,
  isSearchable,
  onInputChange,
  ...rest
}: any) => {
  const customStyles = {
    menu: (provided: any, state: { selectProps: { menuColor: any } }) => ({
      ...provided,
      zIndex: 9999,
      width: '100%',
      color: state.selectProps.menuColor,
    }),
    control: (styles: any, { isDisabled }: any) => {
      return {
        ...styles,
        boxShadow: '0px 3px 6px #2D3748',
        opacity: isDisabled ? 0.7 : 1,
        backgroundColor: '#FAFAFA',
        height: '2.5em',
        borderColor: 'inherit',
        width: '100%',
        borderRadius: '500px',
      }
    },
    option: (provided: any, state: { isSelected: any }) => ({
      ...provided,
      borderBottom: '1px solid grey.100',
      borderRadius: '5px',
      padding: '10px 24px',
      background: state.isSelected
        ? useColorModeValue('theme.light.redInterm', 'theme.dark.redInterm')
        : useColorModeValue('theme.light.body', 'theme.dark.body'),
    }),
    singleValue: (provided: any, state: { isDisabled: any }) => {
      const opacity = state.isDisabled ? 0.7 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition }
    },
  }

  return (
    <Select
      options={options}
      placeholder={placeholder}
      noOptionsMessage={() => 'Nenhum resultado encontrado'}
      onChange={(option: any) => {
        onChange(option?.value)
        if (setValue) {
          setValue(option?.value)
        }
      }}
      isClearable
      isSearchable={isSearchable}
      onInputChange={onInputChange}
      styles={customStyles}
      {...rest}
    />
  )
}
