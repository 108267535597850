import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import EnterprisesForm from '../../../components/forms/ProductForm'

import { useFetch } from '../../../hooks/useFetch'
import SmartWeekForm from '../../../components/forms/SmartWeekForm'

export default function SmartWeekCadastroPage() {
  const { itemId } = useParams()

  const { requestApi, data } = useFetch(`v1/habitational_unity/${itemId}`)

  useEffect(() => {
    const getInfos = async () => {
      if (itemId !== undefined) {
        await requestApi('get')
      }
    }
    getInfos()
  }, [])

  return data?.habitational_unity ? <SmartWeekForm defaultValues={data?.habitational_unity ?? []} /> : <div />
}
