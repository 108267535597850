/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react'
import {
  Container,
  Flex,
  Heading,
  Text,
  Image,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Box,
  Button,
} from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { ResetPassEmailForm, ResetPassPasswordForm, ResetPassPinForm } from '../../../components/forms/ResetPassForm'
import logo from '../../../assets/logo.svg'
import { useToast } from '@chakra-ui/react'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { BiSave } from 'react-icons/bi'

export default function LoginPage() {
  const schema = Yup.object().shape({
    password: Yup.string()
      .required('Campo obrigatório.')
      .min(6, 'Senha deve conter pelo menos 6 caracteres.')
      .max(20, 'Senha deve conter no máximo 20 caracteres.'),
    password_confirmation: Yup.string()
      .required('Campo obrigatório.')
      .oneOf([Yup.ref('password'), null], 'Senhas não conferem.'),
  })
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const toast = useToast()
  const variavel = searchParams.get('token')
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const handleChangePassword = async (values: any) => {
    console.log('values', values)
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/update_password`,
        {
          password: values.password,
        },
        {
          headers: {
            Authorization: `Bearer ${variavel}`,
          },
        },
      )

      if (response.status < 203) {
        toast({
          title: `Sucesso!`,
          position: 'top',
          status: 'success',
          isClosable: true,
        })
        window.location.href = `${process.env.REACT_APP_FRONT ?? '/'}`
        //navigate('/')
      } else if (response.status === 401) {
        toast({
          title: `Token inválido!`,
          position: 'top',
          status: 'error',
          isClosable: true,
        })
      } else {
        toast({
          title: `Ocorreu um erro ae executar a ação!`,
          position: 'top',
          status: 'error',
          isClosable: true,
        })
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        toast({
          title: `Token inválido!`,
          position: 'top',
          status: 'error',
          isClosable: true,
        })
      } else {
        toast({
          title: `Ocorreu um erro ae executar a ação!`,
          position: 'top',
          status: 'error',
          isClosable: true,
        })
      }
    }
  }

  return (
    <Flex minH="100vh">
      <Container maxW="540px" p="10px">
        <Flex align="center" justify="center" mt="50px" mb="30px">
          <Image maxH="200px" src={logo} />
        </Flex>
        <Flex maxW="500px" direction="column" align="center" justify="center" p="50px">
          <Heading fontSize={23} mb="6px">
            Alteração de senha
          </Heading>
          <Text fontSize={16} mb="40px">
            Digite sua nova senha nos campos abaixo
          </Text>
          <Flex w={'full'} direction="column">
            <FormControl isInvalid={errors.password && true}>
              <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                Senha
              </FormLabel>
              <Input
                type="password"
                id="password"
                placeholder="Digite sua senha"
                isRequired
                {...register('password')}
              />
              <FormErrorMessage>{`${errors.password?.message}`}</FormErrorMessage>
            </FormControl>
            <Box w={1} h={5} />
            <FormControl isInvalid={errors.password_confirmation && true}>
              <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                Confimação de Senha
              </FormLabel>
              <Input
                type="password"
                id="password_confirmation"
                placeholder="Digite sua senha novamente"
                isRequired
                {...register('password_confirmation')}
              />
              <FormErrorMessage>{`${errors.password_confirmation?.message}`}</FormErrorMessage>
            </FormControl>
            <Box w={1} h={5} />
            <Button
              onClick={handleSubmit(handleChangePassword)}
              isLoading={isSubmitting}
              leftIcon={<BiSave />}
              colorScheme="red"
              variant="solid"
            >
              Alterar senha
            </Button>
          </Flex>
        </Flex>
      </Container>
    </Flex>
  )
}
