import React from 'react'
import { Box, Flex, Text, Image, Icon } from '@chakra-ui/react'
import { VscError } from 'react-icons/vsc'
import { AiOutlineAudio, AiOutlineCheckCircle } from 'react-icons/ai'
import { GoDotFill } from 'react-icons/go'
import { MdOndemandVideo } from 'react-icons/md'
import { CgFileDocument } from 'react-icons/cg'
import { handleImgError } from './HandleFunctions'

export const getCellExclusive = (v: boolean) => {
  if (v === true) {
    return <AiOutlineCheckCircle color="green" fontSize={25} />
  }
  return <VscError color="red" fontSize={25} />
}

export const getCellStatus = (v: string) => {
  if (v === '1') {
    return <span style={{ color: 'white', padding: 4, background: 'green', borderRadius: 5, fontSize: 14 }}>Ativo</span>
  }
  return <span style={{ color: 'white', padding: 4, background: 'red', borderRadius: 5, fontSize: 14 }}>Inativo</span>
}

export const userType = (item: string) => {
  if (item === '1') {
    return 'Administrador'
  }
  if (item === '2') {
    return 'Colaborador'
  }
}

export const getCellGeneric = (item: any) => {
  if (item) {
    return 'Não informado(a)'
  } else {
    return item
  }
}

export const getCellColor = (value: any) => {
  if (value)
    return (
      <Flex align="center">
        <Box bg={value} borderRadius="5px" w="20px" h="20px" mr="5px" />
        <Text>{value}</Text>
      </Flex>
    )
  return 'Não informado'
}

export const getCellImage = (value: any) => {
  if (value?.medium) return <Image w="50px" borderRadius="5px" onError={handleImgError as any} src={value.medium} />
  return 'Não informado'
}

export const getFileTypeIcon = (value: any) => {
  if (value === 'video')
    return (
      <Flex align="center">
        <Icon as={MdOndemandVideo} mr="5px" fontSize="25px" />
        <Text>Vídeo</Text>
      </Flex>
    )
  if (value === 'audio')
    return (
      <Flex align="center">
        <Icon as={AiOutlineAudio} mr="5px" fontSize="25px" />
        <Text>Audio</Text>
      </Flex>
    )
  if (value === 'docs')
    return (
      <Flex align="center">
        <Icon as={CgFileDocument} mr="5px" fontSize="25px" />
        <Text>Documento</Text>
      </Flex>
    )
  return 'Não informado'
}

export const getStatusDot = (value: any) => {
  if (value.length > 0)
    return [
      ...(value?.map(
        (item: {
          status: any
          name:
            | string
            | number
            | boolean
            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined
        }) => (
          // eslint-disable-next-line react/jsx-key
          <Flex align="center">
            <Icon as={GoDotFill} mr="5px" color={item.status ? 'green' : 'red'} fontSize="15px" />
            <Text>{item.name}</Text>
          </Flex>
        ),
      ) ?? []),
    ]
  return 'Não informado'
}
