/* eslint-disable react/no-children-prop */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Text,
  Image,
  useDisclosure,
  FormErrorMessage,
  FormControl,
  FormLabel,
  SimpleGrid,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  InputRightAddon,
  InputGroup,
} from '@chakra-ui/react'
import moment from 'moment'
import { useToast } from '@chakra-ui/react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate, useParams } from 'react-router-dom'
import IProducts from '../../../interfaces/IProducts'
import { useFetch } from '../../../hooks/useFetch'
import { BiLeftArrow, BiRadar, BiSave, BiTrash, BiUpload } from 'react-icons/bi'
import { api } from '../../../services/api'
import { STORAGE_KEYS } from '../../../constants/Config'
import IHabitational_Unity from '../../../interfaces/IHabitational_Unity'

interface FormProps {
  defaultValues?: any
}

interface FormInputsProps {
  name: string
  value: string
  placeholder?: string
  label?: string
  yup?: any
}

export default function SmartWeekForm({ defaultValues = {} }: FormProps) {
  const baseUrl = 'v1/habitational_unity'
  const { requestApi, error, data } = useFetch(baseUrl)
  const { onOpen } = useDisclosure()
  const { itemId } = useParams()
  const navigate = useNavigate()
  const yupschema: any = {}
  const defaultValuesSchema: any = {}
  const toast = useToast()
  const user = JSON.parse(localStorage.getItem(STORAGE_KEYS.AUTH as string) as string)
  const formInputs: FormInputsProps[] = [
    {
      name: 'room_type',
      value: defaultValues.room_type ?? '',
      placeholder: '',
      label: 'Titulo',
      yup: Yup.string().required('Este campo é obrigatório!').min(3, 'Nome deve ter pelo menos 3 caracteres'),
    },
    {
      name: 'pax',
      value: defaultValues.pax ?? '',
      placeholder: '',
      label: 'pax',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'value',
      value: defaultValues?.value ?? '',
      placeholder: '',
      label: 'Valor',
      yup: Yup.string(),
    },
  ]

  formInputs.forEach(object => {
    yupschema[object.name] = object.yup
    defaultValuesSchema[object.name] = object.value
  })
  const schema = Yup.object().shape(yupschema)

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  })

  const pax = formInputs.find(field => field.name === 'pax')
  const room_type = formInputs.find(field => field.name === 'room_type')
  const value = formInputs.find(field => field.name === 'value')

  const onSubmit = async (values: any) => {
    const vv: IHabitational_Unity = values
    const response = await requestApi(
      defaultValues.room_type ? 'put' : 'post',
      { ...vv },
      defaultValues.room_type ? `${baseUrl}/${itemId}` : null,
    )

    if (response.status < 299) {
      toast({
        title: `Sucesso!`,
        position: 'top',
        status: 'success',
        isClosable: true,
      })
      if (!error) {
        onOpen()
      } else {
        onOpen()
      }
      navigate('/smartweeks')
    } else {
      toast({
        title: `Ocorreu um erro ae executar a ação! ${error.message}`,
        position: 'top',
        status: 'error',
        isClosable: true,
      })
    }
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Flex flexDir="column" ml="4vw" w="73vw">
        <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
          <Flex flexDir="column" mb="40px">
            <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
              Informações básicas
            </Text>
            <SimpleGrid columns={2} spacing={2}>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.room_type && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Descrição
                  </FormLabel>
                  <Input id="description" placeholder={room_type?.label} isRequired {...register('room_type')} />
                  <FormErrorMessage>{`${errors.room_type?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.pax && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    PAX
                  </FormLabel>
                  <Input id="pruduct_vindi_id" placeholder={pax?.label} isRequired {...register('pax')} />
                  <FormErrorMessage>{`${errors.pax?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box maxW="md" mr="1">
                <FormControl isInvalid={errors.value && true}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Valor
                  </FormLabel>
                  <Input type="number" id="value" placeholder={value?.label} isRequired {...register('value')} />
                  <FormErrorMessage>{`${errors.value?.message}`}</FormErrorMessage>
                </FormControl>
              </Box>
            </SimpleGrid>
          </Flex>
        </Box>
        <Box mb="200px" display="flex" justifyContent="space-between">
          <Button
            onClick={() => navigate('/smartweeks')}
            leftIcon={<BiLeftArrow />}
            colorScheme="red"
            variant="outline"
          >
            Cancelar e voltar
          </Button>
          <Button isLoading={isSubmitting} type="submit" rightIcon={<BiSave />} colorScheme="red" variant="solid">
            Salvar
          </Button>
        </Box>
      </Flex>
    </form>
  )
}
