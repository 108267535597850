import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import EnterprisesForm from '../../../components/forms/EnterprisesForm'

import { useFetch } from '../../../hooks/useFetch'

export default function UsersCadastroPage() {
  const { itemId } = useParams()

  const { requestApi, data } = useFetch(`v1/enterprises/${itemId}`)

  useEffect(() => {
    const getInfos = async () => {
      if (itemId !== undefined) {
        await requestApi('get')
      }
    }
    getInfos()
  }, [])

  return data?.enterprise ? (
    <EnterprisesForm defaultValues={data?.enterprise ?? []} defaultValues2={data ?? []} />
  ) : (
    <EnterprisesForm defaultValues={[]} defaultValues2={[]} />
  )
}
