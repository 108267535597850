import React from 'react'
import { Container, Flex, Heading, Text, Center, Image, useMediaQuery } from '@chakra-ui/react'

import { useAuth } from '../../../providers/auth'
import Loader from '../../../components/Loader'
import logo from '../../../assets/logo.svg'
import LoginForm from '../../../components/forms/LoginForm'

export default function LoginPage() {
  const { loading } = useAuth()
  const [isSmallerThan1280] = useMediaQuery('(max-width: 767px)')

  if (loading) {
    return <Loader />
  }

  return (
    <Flex
      direction={['column', 'row']}
      h="full"
      minH="100vh"
      bg="linear-gradient(90deg, rgba(229,229,229,1) 0%, rgba(255,255,255,1) 62%, rgba(255,255,255,1) 77%)"
    >
      {!isSmallerThan1280 && (
        <Flex w="full" minH="100vh" align="center" justify="center">
          <Flex align="center" justify="center">
            <Image maxH="200px" src={logo} />
          </Flex>
        </Flex>
      )}
      <Center w="full" minH="100vh" backgroundColor="#000" backdropFilter="blur(8px)" justifyContent="center">
        <Container maxW="400px">
          <Heading fontSize={33} color="#FFFFFF" mb="6px">
            Entrar
          </Heading>
          <Text fontSize={20} color="#FFFFFF" mb="40px">
            Digite suas informações para acessar
          </Text>
          <LoginForm />
        </Container>
      </Center>
    </Flex>
  )
}
