import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

import { colors } from './colors'
// import { fonts } from './fonts'

const breakpoints = createBreakpoints({
  sm: '48em',
  md: '48em',
  lg: '62em',
  xl: '96em',
})

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        // fontFamily: 'Gotham',
      },
    },
  },
  breakpoints,
  colors,
  // fonts,
  components: {
    Container: {
      sizes: {
        xl: {
          maxW: '1280px',
        },
        x2: {
          maxW: '800px',
        },
      },
    },
  },
})
