import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import EnterprisesForm from '../../../components/forms/ProductForm'

import { useFetch } from '../../../hooks/useFetch'

export default function UsersCadastroPage() {
  const { itemId } = useParams()

  const { requestApi, data } = useFetch(`v1/products/${itemId}`)

  useEffect(() => {
    const getInfos = async () => {
      if (itemId !== undefined) {
        await requestApi('get')
      }
    }
    getInfos()
  }, [])

  return <EnterprisesForm defaultValues={data?.products ?? []} />
}
