import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { BiAddToQueue, BiBookAdd, BiMessageAdd } from 'react-icons/bi'
import { FiUserPlus } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

const DashboardCard = () => {
  const navigate = useNavigate()
  return (
    <Box display="flex" flexDir="column">
      <Box mb="20px">
        <Text fontSize="18px" fontWeight="bold" color="#2D3748">
          Ações rápidas
        </Text>
      </Box>
      <Flex flexDir="row">
        <Box
          cursor="pointer"
          w="190px"
          h="160px"
          mr="70px"
          boxShadow="md"
          bg="#000"
          borderRadius="15px"
          display="flex"
          flexDir="column"
          alignSelf="center"
          alignContent="center"
          alignItems="center"
          justifySelf="center"
          justifyContent="center"
          justifyItems="center"
        >
          <BiAddToQueue fontSize="40px" color="white" />
          <Text fontSize="14px" mt="14px" fontWeight="bold" color="white" textAlign="center">
            Adicionar um novo intercâmbio
          </Text>
        </Box>
        <Box
          onClick={() => navigate('/enterprises/create')}
          cursor="pointer"
          w="190px"
          h="160px"
          mr="70px"
          boxShadow="md"
          bg="#FFFFFF"
          borderRadius="15px"
          display="flex"
          flexDir="column"
          alignSelf="center"
          alignContent="center"
          alignItems="center"
          justifySelf="center"
          justifyContent="center"
          justifyItems="center"
        >
          <BiBookAdd fontSize="40px" color="#000" />
          <Text fontSize="14px" mt="14px" fontWeight="bold" color="#33303E" textAlign="center">
            Criar um novo empreendimento
          </Text>
        </Box>
        <Box
          onClick={() => navigate('/users/create')}
          cursor="pointer"
          w="190px"
          h="160px"
          mr="70px"
          boxShadow="md"
          bg="#FFFFFF"
          borderRadius="15px"
          display="flex"
          flexDir="column"
          alignSelf="center"
          alignContent="center"
          alignItems="center"
          justifySelf="center"
          justifyContent="center"
          justifyItems="center"
        >
          <FiUserPlus fontSize="40px" color="#000" />
          <Text fontSize="14px" mt="14px" fontWeight="bold" color="#33303E" textAlign="center">
            Adicionar novo usuário
          </Text>
        </Box>
        <Box
          cursor="pointer"
          w="190px"
          h="160px"
          mr="70px"
          boxShadow="md"
          bg="#FFFFFF"
          borderRadius="15px"
          display="flex"
          flexDir="column"
          alignSelf="center"
          alignContent="center"
          alignItems="center"
          justifySelf="center"
          justifyContent="center"
          justifyItems="center"
        >
          <FiUserPlus fontSize="40px" color="#000" />
          <Text fontSize="14px" mt="14px" fontWeight="bold" color="#33303E" textAlign="center">
            Fazer uma reserva
          </Text>
        </Box>
        <Box
          cursor="pointer"
          w="190px"
          h="160px"
          mr="70px"
          boxShadow="md"
          bg="#FFFFFF"
          borderRadius="15px"
          display="flex"
          flexDir="column"
          alignSelf="center"
          p="5px"
          alignContent="center"
          alignItems="center"
          justifySelf="center"
          justifyContent="center"
          justifyItems="center"
        >
          <BiMessageAdd fontSize="40px" color="#000" />
          <Text fontSize="14px" mt="14px" fontWeight="bold" color="#33303E" textAlign="center">
            Enviar uma nova notificação
          </Text>
        </Box>
      </Flex>
    </Box>
  )
}
export default DashboardCard
