/* eslint-disable react/no-children-prop */
import React from 'react'
import { BsFillInfoCircleFill } from 'react-icons/bs'
import { IconButton } from '@chakra-ui/react'

export const InfoButton = ({ onClick, ...props }: any) => {
  return (
    <IconButton
      onClick={onClick}
      // color={useColorModeValue('theme.light.blueStrong', 'theme.dark.blueStrong')}
      icon={<BsFillInfoCircleFill />}
      size="sm"
      mr={2}
      variant="ghost"
      _hover={{ opacity: 0.5 }}
      aria-label=""
      {...props}
    />
  )
}
