import { Box } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import Loader from '../components/Loader'
import NavBarAuth from '../layout/NavBar'
import SideBar from '../layout/SideBar'
import { useAuth } from '../providers/auth'

export const RequireAuth = () => {
  const { signed, loading, user } = useAuth()
  const location = useLocation()
  // const navigate = useNavigate()
  useEffect(() => {
    console.log('signed', user)
  }, [user])
  const viewportHeight = window.innerHeight
  const secureHeight = viewportHeight - viewportHeight / 4.5
  if (loading) {
    return <Loader />
  }

  return signed ? (
    <Box>
      <Box display="flex" flexDir="row" bg="#F7FAFC">
        <SideBar />
        <Box display="flex" flexDir="column" minHeight={secureHeight}>
          <NavBarAuth />
          <Outlet />
        </Box>
      </Box>
    </Box>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  )
}
