import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ReservationsForm from '../../../components/forms/Reservations'
import { api } from '../../../services/api'
import { Stack, HStack, VStack, Text } from '@chakra-ui/react'
import { useFetch, useFetchCPT } from '../../../hooks/useFetch'

export default function UsersCadastroPage() {
  const { itemId } = useParams()
  const [isLoading, setLoading] = useState(true)
  const [booking, setBooking] = useState<any>({})

  const reservation = useFetch(`v1/reservations/${itemId}`)

  useEffect(() => {
    const getInfos = async () => {
      if (itemId !== undefined) {
        try {
          await reservation.requestApi('get')
          console.log('Reserva2:: ', reservation)
          const data = await api.get(`/v1/view/reservation/${itemId}`)
          console.log('Reserva:: ', data)
          setBooking(data.data)
          setLoading(false)
        } catch (error) {
          console.log(error)
        }
      }
    }
    getInfos()
  }, [])

  return reservation.loading || isLoading ? (
    <div />
  ) : booking.BookingDetail.BookingNumber ? (
    <ReservationsForm defaultValues={reservation.data ?? []} defaultValues2={booking ?? []} />
  ) : (
    <HStack h={'90vh'} justifyContent={'center'}>
      <Text fontSize="3xl" mt={-35}>
        Erro de comunicação com o motor de buscas (CNG)
      </Text>
    </HStack>
  )
}
// 9, 5, 3 - Calculo o saldo
