import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Heading, Icon, Input, Select, Text, useToast } from '@chakra-ui/react'
import ResumeCard from '../../components/ResumeCard'
import { BiBuildingHouse, BiFilter } from 'react-icons/bi'
import { VscChecklist, VscEdit } from 'react-icons/vsc'
import { DataGrid } from '../../components/DataGrid'
import { SearchFilter } from '../../components/inputs/filters/search'
import { getCellGeneric, getCellStatus } from '../../utils/FormatFunctions'
import AlertModal from '../../components/AlertModal'
import { InfoButton } from '../../components/Buttons/InfoButton'
import { IconGeneralButton } from '../../components/Buttons/IconGeneralButton'
import { ReturnButton } from '../../components/Buttons/ReturnButton'
import { SelectFilter } from '../../components/inputs/filters/select'
import { DateFilter } from '../../components/inputs/filters/date'
import { useFetch } from '../../hooks/useFetch'
import { useNavigate } from 'react-router-dom'
import IEnterprises from '../../interfaces/IEnterprises'
import { api } from '../../services/api'
import ConfirmModal from '../../components/ConfirmModal'
import ReactTable from '../../components/ReactTable'
import { formatCNPJ } from '../../services/utilites'

const Enterprises = () => {
  const baseUrl = '/enterprises'
  const navigate = useNavigate()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [data, setData] = useState<IEnterprises[]>([])
  const [total, setTotal] = useState(0)
  const [isOpen, onOpen] = useState(true)
  const toast = useToast()

  const [enterprise_id, setEnterprise_id] = useState('')
  const [enterprise_name, setEnterprise_name] = useState('')
  const [enterprise_status, setEnterprise_status] = useState('-1')
  const [enterprise_address_country, setEnterprise_address_country] = useState('')
  const [enterprise_address_city, setEnterprise_address_city] = useState('')

  useEffect(() => {
    getData()
  }, [pageNumber, perPage])

  const getData = async () => {
    try {
      const getData = await api.get(`v1/enterprises?pageNumber=${pageNumber}&perPage=${perPage}`)

      if (getData.status === 200) {
        console.log('Aqui:::', getData.data.enterprises)
        setData(getData.data.enterprises)
        setPageSize(getData.data.paginateCount)
        setTotal(getData.data.count)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleFilter = async () => {
    try {
      setData([])
      const getData = await api.post(`v1/enterprises/filter?pageNumber=${pageNumber}&perPage=${perPage}`, {
        enterprise_id,
        enterprise_name,
        enterprise_status,
        enterprise_address_country,
        enterprise_address_city,
      })

      if (getData.status === 200) {
        console.log('Aqui:::', getData.data.enterprises)
        setData(getData.data.enterprises)
        setPageSize(getData.data.paginateCount)
        setTotal(getData.data.count)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteRegister = async (id: number) => {
    try {
      const deleteData = await api.delete(`v1/enterprises/${id}`)

      if (deleteData.status === 200) {
        getData()
        toast({
          title: `Sucesso!`,
          position: 'top',
          status: 'success',
          isClosable: true,
        })
      } else {
        toast({
          title: `Ocorreu um erro ae executar a ação! ${deleteData.data.message}`,
          position: 'top',
          status: 'error',
          isClosable: true,
        })
      }
    } catch (error: any) {
      toast({
        title: `Ocorreu um erro ae executar a ação! ${error!.message}`,
        position: 'top',
        status: 'error',
        isClosable: true,
      })
      console.log(error)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Informações',
        columns: [
          {
            Header: 'Nome',
            accessor: 'enterprise_name',
          },
          {
            Header: 'CNPJ',
            accessor: 'enterprise_cnpj',
            Cell: ({ row }: any) => {
              return <Text>{formatCNPJ(row.original.enterprise_cnpj)}</Text>
            },
          },
          {
            Header: 'Cidade',
            accessor: 'enterprise_address_city',
          },
          {
            Header: 'Status',
            accessor: 'enterprise_status',
            Cell: ({ row }: any) => {
              return row.original.enterprise_status === '1' ? (
                <Text color="green.500">Ativo</Text>
              ) : (
                <Text color="red.500">Inativo</Text>
              )
            },
          },
          {
            Header: 'Ações',
            accessor: 'actions',
            Cell: ({ row }: any) => (
              <Flex align="center">
                <IconGeneralButton
                  Icon={VscEdit}
                  color="#1890FF"
                  fontSize="25px"
                  onClick={() => {
                    console.log('Editar', row.original)
                    navigate(`${row.original.enterprise_id}/edit`)
                  }}
                />
                {ConfirmModal(() => deleteRegister(row.original.enterprise_id))}
              </Flex>
            ),
          },
        ],
      },
    ],
    [],
  )

  return (
    <Flex flexDir="column" mt="7vh" ml="4vw" w="73vw">
      <Box display="flex" flexDir="column">
        <Box
          display="flex"
          alignContent="center"
          justifyItems="center"
          justifySelf="center"
          alignItems="center"
          flexDir="row"
          mb="20px"
        >
          <ResumeCard title="Todas as propriedades" icon={<BiBuildingHouse color="#fff" />} subititle={`${total}`} />
          <Box w="80px" />
          <Button onClick={() => navigate('/enterprises/create')} colorScheme="red" variant="solid">
            Adicionar propriedade +
          </Button>
        </Box>
      </Box>
      <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
        <Text mb="20px" fontSize="24px" fontWeight="bold" color="#2D3748">
          Propriedades
        </Text>
        <Flex flexDir="column" mb="40px">
          <Box display="flex" flexDir="row">
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                ID
              </Text>
              <Input
                type="number"
                placeholder="Ex: 589"
                value={enterprise_id}
                onChange={e => setEnterprise_id(e.target.value)}
              />
            </Box>
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Nome
              </Text>
              <Input
                placeholder="Ex: Hotel Central"
                value={enterprise_name}
                onChange={e => setEnterprise_name(e.target.value)}
              />
            </Box>
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Status
              </Text>
              <Select value={enterprise_status} onChange={e => setEnterprise_status(e.target.value)}>
                <option value="-1">Todos</option>
                <option value="1">Ativo</option>
                <option value="0">Inativo</option>
              </Select>
            </Box>
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                País
              </Text>
              <Input
                placeholder="Ex: Brasil"
                value={enterprise_address_country}
                onChange={e => setEnterprise_address_country(e.target.value)}
              />
            </Box>
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Cidade
              </Text>
              <Input
                placeholder="Ex: São Paulo"
                value={enterprise_address_city}
                onChange={e => setEnterprise_address_city(e.target.value)}
              />
            </Box>
          </Box>
          <Box mt="20px" display="flex" flexDir="row">
            <Box display="flex" alignContent="end" alignItems="end">
              <Button leftIcon={<BiFilter />} onClick={handleFilter} colorScheme="red" variant="solid">
                Filtrar
              </Button>
            </Box>
          </Box>
        </Flex>
        <Box>
          <Flex align="center" mb={5}>
            <ReturnButton previousPage="gamification" />

            <Heading as="h1" size="lg">
              Lista de propriedades
            </Heading>
          </Flex>

          <ReactTable
            columns={columns}
            pageNumber={pageNumber}
            data={data}
            totaPages={pageSize}
            onChange={(e: any) => {
              console.log('Akkkk:: ', e)
              setPageNumber(e.pageIndex + 1)
              setPerPage(e.sPageSize)
            }}
          />
        </Box>
      </Box>
    </Flex>
  )
}
export default Enterprises
