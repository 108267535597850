import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes/index'
import './global.css'
import { AuthProvider } from './providers/auth'

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </BrowserRouter>
  )
}
