import { AxiosStatic } from 'axios'
import { getAuthStorage, removeAuthStorage, setAuthStorage } from './storage'

// eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
const axios = require('axios') as AxiosStatic

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})

export const cangooroo = axios.create({
  baseURL: 'https://cpt.cangooroo.net/',
  headers: {
    'Content-Type': 'application/json',
  },
})

export const cpt = axios.create({
  baseURL: process.env.REACT_APP_CPT_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const gw = axios.create({
  baseURL: process.env.REACT_APP_GW_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.response.use(
  response => {
    return response
  },
  // eslint-disable-next-line @typescript-eslint/no-shadow
  async error => {
    if (error.response.status === 403) {
      if (error.config.url.includes('auth') === true || error.config.url.includes('recover') === true) {
        return Promise.reject(error)
      }

      if (error.response.data.error.includes('Invalid token')) {
        refreshTokenByCookie()
        return
      }

      // eslint-disable-next-line no-use-before-define
      //const token = await refreshToken()

      // eslint-disable-next-line no-param-reassign
      //error.config.headers.Authorization = `Bearer ${token}`

      //return api(error.config)
    }

    return Promise.reject(error)
  },
)

api.interceptors.request.use(
  async config => {
    const storageAuth = await getAuthStorage()
    if (storageAuth && storageAuth.token) {
      if (!config.headers) {
        config.headers = {}
      }
      config.headers['Authorization'] = `Bearer ${storageAuth.token}`
      config.headers['REFRESH_TOKEN'] = `${storageAuth?.refreshToken}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  },
)

//REFRESH TOKEN BY COOKIE
const refreshTokenByCookie = async () => {
  try {
    const { data } = await api.post('/v1/refresh_token')
    await setAuthStorage(data)
    location.reload()
  } catch (error) {
    removeAuthStorage()
    location.reload()
  }
}

// const refreshToken = async () => {
//   try {
//     const storageAuth = await getAuthStorage()
//     if (storageAuth) {
//       api.defaults.headers.common.Authorization = `Bearer ${storageAuth.refresh_token}`
//     }

//     const token = storageAuth.token
//     api.defaults.headers.common.Authorization = `Bearer ${token}`
//     return token
//   } catch (error) {
//     return error
//   }
// }

//CORRECT REFRESH TOKEN
// const refreshToken = async () => {
//   try {
//     const storageAuth = await getAuthStorage()
//     if (storageAuth) {
//       api.defaults.headers.common.Authorization = `Bearer ${storageAuth.refresh_token}`
//     }
//     const res = await api.post('/auth/refresh')
//     if (res.status === 200) {
//       await setAuthStorage(res.data)
//     } else {
//       removeAuthStorage()
//       return Promise.reject()
//     }
//     const token = res.data.access_token
//     api.defaults.headers.common.Authorization = `Bearer ${token}`
//     return token
//   } catch (error) {
//     return error
//   }
// }
