import React, { useEffect, useState } from 'react'
import { Box, Flex, Heading, Text, useToast } from '@chakra-ui/react'
import ResumeCard from '../../components/ResumeCard'
import { BiBuildingHouse } from 'react-icons/bi'
import { VscEdit } from 'react-icons/vsc'
import { IconGeneralButton } from '../../components/Buttons/IconGeneralButton'
import { ReturnButton } from '../../components/Buttons/ReturnButton'
import { useNavigate } from 'react-router-dom'
import IHabitational_Unity from '../../interfaces/IHabitational_Unity'
import { api } from '../../services/api'
import ReactTable from '../../components/ReactTable'

const HabitationalUnits = () => {
  const baseUrl = '/products'
  const navigate = useNavigate()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [data, setData] = useState<IHabitational_Unity[]>([])
  const [total, setTotal] = useState(0)
  const [isOpen, onOpen] = useState(true)
  const toast = useToast()

  useEffect(() => {
    getData()
  }, [pageNumber, perPage])

  const getData = async () => {
    try {
      const getData = await api.get(`v1/habitational_unity?pageNumber=${pageNumber}&perPage=${perPage}`)

      if (getData.status === 200) {
        console.log('Aqui:::', getData.data.habitational_unity)
        setData(getData.data.habitational_unity)
        // setPageSize(getData.data.paginateCount)
        setTotal(getData.data.count)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Informações',
        columns: [
          {
            Header: 'titulo',
            accessor: 'room_type',
          },
          {
            Header: 'PAX',
            accessor: 'pax',
          },
          {
            Header: 'Valor',
            accessor: 'value',
          },
          {
            Header: 'Ações',
            accessor: 'actions',
            Cell: ({ row }: any) => (
              <Flex align="center">
                <IconGeneralButton
                  Icon={VscEdit}
                  color="#1890FF"
                  fontSize="25px"
                  onClick={() => {
                    console.log('Editar', row.original)
                    navigate(`${row.original.habitational_unity_id}/edit`)
                  }}
                />
              </Flex>
            ),
          },
        ],
      },
    ],
    [],
  )

  return (
    <Flex flexDir="column" mt="7vh" ml="4vw" w="73vw">
      <Box display="flex" flexDir="column">
        <Box
          display="flex"
          alignContent="center"
          justifyItems="center"
          justifySelf="center"
          alignItems="center"
          flexDir="row"
          mb="20px"
        >
          <ResumeCard title="Todos os itens" icon={<BiBuildingHouse color="#fff" />} subititle={`${total}`} />
          <Box w="80px" />
        </Box>
      </Box>
      <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
        <Text mb="20px" fontSize="24px" fontWeight="bold" color="#2D3748">
          Unidades habitacionais
        </Text>
        <Box>
          <Flex align="center" mb={5}>
            <ReturnButton previousPage="gamification" />

            <Heading as="h1" size="lg">
              Lista de unidades habitacionais
            </Heading>
          </Flex>

          <ReactTable
            columns={columns}
            pageNumber={pageNumber}
            data={data}
            totaPages={pageSize}
            onChange={(e: any) => {
              console.log('Akkkk:: ', e)
              setPageNumber(e.pageIndex + 1)
              setPerPage(e.sPageSize)
            }}
          />
        </Box>
      </Box>
    </Flex>
  )
}
export default HabitationalUnits
