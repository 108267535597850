import React, { ReactText } from 'react'
import { Box, Icon, Text } from '@chakra-ui/react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { IconType } from 'react-icons/lib'

interface MenuItemProps {
  screen: string
  icon: IconType
  href: any
  title: string
  children: ReactText
}

const MenuItem = ({ screen, children, icon, href, title, ...rest }: MenuItemProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const active = location?.pathname !== undefined && location?.pathname == screen
  return (
    <>
      {active ? (
        <Link {...rest} to={href}>
          <Box mt="25px" display="flex" justifyContent="center" alignItems="center">
            <Box
              cursor="pointer"
              display="flex"
              flexDir="row"
              alignItems="center"
              justifySelf="center"
              borderRadius="10px"
              boxShadow="base"
              bg="white"
              h="55px"
              w="16vw"
            >
              <Box
                display="flex"
                alignSelf="center"
                alignContent="center"
                alignItems="center"
                justifySelf="center"
                justifyContent="center"
                justifyItems="center"
                borderRadius="10px"
                ml="10px"
                w="30px"
                h="30px"
                bg="#000"
              >
                {icon && <Icon as={icon} color="white" />}
              </Box>
              <Text color="#2D3748" fontWeight="bold" fontSize="16px" ml="15px">
                {title}
              </Text>
            </Box>
          </Box>
        </Link>
      ) : (
        <Link {...rest} to={href}>
          <Box mt="25px" display="flex" justifyContent="center" alignItems="center">
            <Box
              onClick={() => navigate(`${screen}`, { replace: true })}
              cursor="pointer"
              display="flex"
              flexDir="row"
              alignItems="center"
              justifySelf="center"
              borderRadius="10px"
              bg="#F7FAFC"
              boxShadow="base"
              h="55px"
              w="16vw"
            >
              <Box
                display="flex"
                alignSelf="center"
                alignContent="center"
                alignItems="center"
                justifySelf="center"
                justifyContent="center"
                justifyItems="center"
                borderRadius="10px"
                ml="10px"
                w="30px"
                h="30px"
                bg="white"
              >
                {icon && <Icon as={icon} color="#718096" />}
              </Box>
              <Text color="#718096" fontWeight="bold" fontSize="16px" ml="15px">
                {children}
              </Text>
            </Box>
          </Box>
        </Link>
      )}
    </>
  )
}
export default MenuItem
