/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import moment from 'moment'
import { VscChecklist } from 'react-icons/vsc'
import { Box, Flex, Heading, Icon, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { DataGrid } from '../../components/DataGrid'
import { SearchFilter } from '../../components/inputs/filters/search'
import { useFetch } from '../../hooks/useFetch'
import { getCellImage, getCellStatus } from '../../utils/FormatFunctions'
import AlertModal from '../../components/AlertModal'
import { InfoButton } from '../../components/Buttons/InfoButton'
import { IconGeneralButton } from '../../components/Buttons/IconGeneralButton'
import { ReturnButton } from '../../components/Buttons/ReturnButton'
import { SelectFilter } from '../../components/inputs/filters/select'
import { DateFilter } from '../../components/inputs/filters/date'

export default function TestePage() {
  const baseUrl = 'https://jsonplaceholder.typicode.com/posts'
  const { requestApi, data, loading, error } = useFetch(baseUrl)
  const navigate = useNavigate()

  const columns = [
    { name: 'id', label: 'Id' },
    { name: 'body', label: 'Body' },
    { name: 'name', label: 'Nome' },
    { name: 'title', label: 'Título' },
    { name: 'userId', label: 'User ID' },
  ]

  return (
    <Box>
      <Flex align="center" mb={5}>
        <ReturnButton previousPage="gamification" />

        <Heading as="h1" size="lg">
          Página teste
        </Heading>
      </Flex>

      <DataGrid
        isId={'id'}
        columns={columns}
        rows={data != null && data}
        requestApi={requestApi}
        error={error}
        baseUrl={baseUrl}
        data={data}
        hasCreate
        loading={loading}
        infoButton={[
          {
            name: 'steps',
            button: (
              <AlertModal
                alertMsg={
                  <Flex align="center">
                    <Icon as={VscChecklist} color="#1890FF" mr="5px" fontSize="25px" />
                    <Text>Clique no ícone para listar as etapas de teste</Text>
                  </Flex>
                }
                msgStatus="info"
                ButtonOpen={InfoButton}
              />
            ),
          },
        ]}
        columnWithAction={[
          {
            name: 'steps',
            columnAction: (rowItem: {
              id: any
              total_comment:
                | string
                | number
                | boolean
                | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                | React.ReactFragment
                | React.ReactPortal
                | null
                | undefined
            }) => (
              <Flex align="center">
                <IconGeneralButton
                  Icon={VscChecklist}
                  color="#1890FF"
                  fontSize="25px"
                  onClick={() => navigate(`${rowItem.id}`)}
                />
                <Text fontWeight="bold">{rowItem.total_comment}</Text>
              </Flex>
            ),
          },
        ]}
        rowActions={{ show: false, edit: true, delete: true }}
        formatColum={[
          {
            field: 'image',
            formatField: getCellImage,
          },
          {
            field: 'date_start',
            formatField: (value: moment.MomentInput) => moment(value).format('DD/MM/yyyy'),
          },
          {
            field: 'date_end',
            formatField: (value: moment.MomentInput) => moment(value).format('DD/MM/yyyy'),
          },
          {
            field: 'status',
            formatField: getCellStatus,
          },
        ]}
        headerFilters={[
          {
            name: 'search',
            filterComponent: SearchFilter,
          },
          {
            name: 'type',
            filterComponent: SelectFilter,
            placeholder: 'Selecionar tipo',
            options: [
              {
                label: 'Extraordinária',
                value: 'extraordinaria',
              },
              {
                label: 'Semanal',
                value: 'semanal',
              },
            ],
          },
          {
            name: 'date_start',
            filterComponent: DateFilter,
            placeholder: 'Selecionar data inicial',
          },
          {
            name: 'date_end',
            filterComponent: DateFilter,
            placeholder: 'Selecionar data final',
          },
        ]}
      />
    </Box>
  )
}
