import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Text,
  Input,
  Heading,
  SimpleGrid,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { BiBuildingHouse, BiFilter } from 'react-icons/bi'
import ReactTable from '../../components/ReactTable'

import { useFetchGW } from '../../hooks/useFetch'
import { gw } from '../../services/api'
import moment from 'moment'

const GW = () => {
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [data, setData] = useState<[]>([])
  const [total, setTotal] = useState(0)
  const [filter, setFilter] = useState('')

  useEffect(() => {
    getData()
  }, [pageNumber, perPage])

  const handleFilter = () => {
    getData()
  }

  const getData = async () => {
    try {
      const getData = await gw.get(`v1/qrcodes?searchParam=${filter}&pageNumber=${pageNumber}&perPage=${perPage}`)

      if (getData.status === 200) {
        console.log('Aqui:::', getData.data.products)
        setData(getData.data.qrcode)
        setPageSize(getData.data.paginateCount)
        setTotal(getData.data.count)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Informações',
        columns: [
          {
            Header: 'Codigo',
            accessor: 'qrcode_number',
          },
          {
            Header: 'Nome',
            accessor: 'user_firstname',
            Cell: ({ row }: any) => {
              return (
                <Text>
                  {row.original.qrcodeUsers !== null ? row.original.qrcodeUsers.user.user_firstname : 'Sem usuário'}
                </Text>
              )
            },
          },
          {
            Header: 'Sobrenome',
            accessor: 'user_lastname',
            Cell: ({ row }: any) => {
              return (
                <Text>
                  {row.original.qrcodeUsers !== null ? row.original.qrcodeUsers.user.user_lastname : 'Sem usuário'}
                </Text>
              )
            },
          },
          {
            Header: 'Full code',
            accessor: 'qrcode_fullcode',
          },
          {
            Header: 'Criação',
            accessor: 'created_at',
            Cell: ({ row }: any) => {
              return <Text>{moment(row.original.created_at, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Text>
            },
          },
          {
            Header: 'Expiraçãp',
            accessor: 'qrcode_expired_at',
            Cell: ({ row }: any) => {
              return <Text>{moment(row.original.qrcode_expired_at, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Text>
            },
          },
        ],
      },
    ],
    [],
  )

  return (
    <Flex flexDir="column" ml="4vw" w="73vw">
      <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
        <Tabs>
          <TabList>
            <Tab>
              <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
                Lista de vouchers
              </Text>
            </Tab>
            <Tab>
              <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
                Parametros do sistema
              </Text>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Flex flexDir="column" mb="40px">
                <Heading as="h1" size="lg">
                  Vouchers
                </Heading>
              </Flex>
              <Flex flexDir="column" mb="40px">
                <Box display="flex" flexDir="row">
                  <Box width="16vw" mr="1vw">
                    <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                      Fullcode
                    </Text>
                    <Input placeholder="FullCode" value={filter} onChange={e => setFilter(e.target.value)} />
                  </Box>
                  <Box mt="20px" display="flex" flexDir="row">
                    <Box display="flex" alignContent="end" alignItems="end">
                      <Button leftIcon={<BiFilter />} onClick={handleFilter} colorScheme="red" variant="solid">
                        Filtrar
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Flex>
              <ReactTable
                columns={columns}
                pageNumber={pageNumber}
                data={data}
                totaPages={pageSize}
                onChange={(e: any) => {
                  console.log('Akkkk:: ', e)
                  setPageNumber(e.pageIndex + 1)
                  setPerPage(e.sPageSize)
                }}
              />
            </TabPanel>
            <TabPanel>
              <Flex alignItems={'center'} justifyContent={'space-between'}></Flex>
              <SimpleGrid columns={3} spacing={2} mt={10}></SimpleGrid>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  )
}
export default GW
