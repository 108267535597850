import React, { useEffect, useState } from 'react'
import DashboardCard from '../../components/DashboardCard'
import ResumeCard from '../../components/ResumeCard'
import { BiBuildingHouse, BiCalendar, BiRepeat, BiUser, BiWallet } from 'react-icons/bi'
import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useFetch } from '../../hooks/useFetch'
import { format } from 'date-fns'
import { VscChecklist } from 'react-icons/vsc'
import { DataGrid } from '../../components/DataGrid'
import { getCellStatus, userType } from '../../utils/FormatFunctions'
import AlertModal from '../../components/AlertModal'
import { InfoButton } from '../../components/Buttons/InfoButton'
import { IconGeneralButton } from '../../components/Buttons/IconGeneralButton'
import { api } from '../../services/api'
import ReactTable from '../../components/ReactTable'
import moment from 'moment'

const Dashboard = () => {
  const baseUrl = '/exchanges'
  const { requestApi, data, loading, error } = useFetch(baseUrl)
  const navigate = useNavigate()
  const [dados, setDados] = useState<any>({})
  const [dados2, setDados2] = useState<any>([])
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(1)
  const [perPage, setPerPage] = useState(10)

  useEffect(() => {
    getInfos()
  }, [])

  const getInfos = async () => {
    try {
      const getData = await api.get(`v1/dashboard`)

      if (getData.status === 200) {
        console.log('Aqui:::', getData.data)
        setDados(getData.data)
      }

      const getData2 = await api.get(`v1/ownership/0?reviewed=true`)

      if (getData2.status === 200) {
        console.log('Aqui:::', getData2.data)
        setDados2(getData2.data.ownership)
        setPageSize(getData2.data.paginateCount)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Empreendimento',
        columns: [
          {
            Header: 'Nome',
            accessor: 'enterprise.enterprise_name',
          },
        ],
      },
      {
        Header: 'Info',
        columns: [
          {
            Header: 'Unidade Habitacional',
            accessor: 'habitational_unity.room_type',
          },
          {
            Header: 'Data de entrada',
            accessor: 'start_at',
            Cell: ({ row }: any) => {
              return <Text>{moment(new Date(row.original.start_at.toString())).format('DD/MM/YYYY').toString()}</Text>
            },
          },
          {
            Header: 'Data de saída',
            accessor: 'end_at',
            Cell: ({ row }: any) => {
              return <Text>{moment(new Date(row.original.end_at.toString())).format('DD/MM/YYYY').toString()}</Text>
            },
          },
        ],
      },
    ],
    [],
  )
  return (
    <Flex flexDir="column" mt="7vh" ml="4vw" w="73vw">
      <DashboardCard />

      <Box display="flex" flexDir="column">
        <Box mt="20px" mb="20px">
          <Text fontSize="18px" fontWeight="bold" color="#2D3748">
            Informações rápidas
          </Text>
        </Box>
        <Box display="flex" justifyContent="space-between" flexDir="row" mb="20px">
          <ResumeCard title="Todos usuários" icon={<BiUser color="#fff" />} subititle={dados.countUsers} />
          <ResumeCard title="Clientes" icon={<BiWallet color="#fff" />} subititle={dados.countUsersClient} />
          <ResumeCard title="Todas as reservas" icon={<BiCalendar color="#fff" />} subititle="0" />
          <ResumeCard title="Todos os intercâmbios" icon={<BiRepeat color="#fff" />} subititle="0" />
          <ResumeCard
            title="Todas as propriedades"
            icon={<BiBuildingHouse color="#fff" />}
            subititle={dados.countEnterprises}
          />
        </Box>
      </Box>
      <Box mt="20px" mb="20px">
        <Text fontSize="18px" fontWeight="bold" color="#2D3748">
          Últimos intercâmbios
        </Text>
      </Box>

      <Box>
        <ReactTable
          columns={columns}
          pageNumber={pageNumber}
          data={dados2}
          totaPages={pageSize}
          onChange={(e: any) => {
            console.log('Akkkk:: ', e)
            setPageNumber(e.pageIndex + 1)
            setPerPage(e.sPageSize)
          }}
        />
      </Box>
    </Flex>
  )
}
export default Dashboard
