import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ExchangesForm from '../../../components/forms/ExchangesForm'

import { useFetch } from '../../../hooks/useFetch'

export default function UsersCadastroPage() {
  const { itemId } = useParams()

  const exchanges = useFetch(`v1/exchanges/${itemId}`)
  const users = useFetch(`v1/users?pageNumber=1&perPage=100000000`)
  const enterprises = useFetch(`v1/enterprises?pageNumber=1&perPage=100000000`)
  const habitational_unity = useFetch(`v1/habitational_unity?pageNumber=1&perPage=100000000`)

  useEffect(() => {
    const getInfos = async () => {
      if (itemId !== undefined) {
        await exchanges.requestApi('get')
      }
      await users.requestApi('get')
      await enterprises.requestApi('get')
      await habitational_unity.requestApi('get')
    }
    getInfos()
  }, [])

  return exchanges.loading || users.loading || enterprises.loading || habitational_unity.loading ? (
    <div />
  ) : (
    <ExchangesForm
      defaultValues={exchanges.data ?? []}
      defaultValues2={exchanges.data ?? []}
      users={users.data?.users ?? []}
      enterprises={enterprises.data?.enterprises ?? []}
      habitational_unity={habitational_unity.data?.habitational_unity ?? []}
    />
  )
}
