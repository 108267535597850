import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { api } from '../services/api'
import { getAuthStorage, removeAuthStorage, setAuthStorage } from '../services/storage'

interface UserImageType {
  large: string
  medium: string
  original: string
  small: string
}

interface UserType {
  id: number
  cpf: string
  cell_phone: string
  email: string
  genre: string
  name: string
  status: boolean
  image: UserImageType | null
}

interface ErrorType {
  status: string
  msg: string
}

interface AuthContextData {
  signed: boolean
  user: object | any
  loading: boolean
  error: ErrorType | string | null | unknown
  // eslint-disable-next-line no-unused-vars
  Login(user: object): Promise<void> | any
  Logout(): void
  getUsers(): any
}

interface AuxProps {
  children: ReactElement | ReactElement[]
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const AuthProvider: React.FC<AuxProps> = ({ children }) => {
  const navigate = useNavigate()
  const [user, setUser] = useState<UserType | object | null>(null)
  const [users, setUsers] = useState<object | null>(null)
  const [error, setError] = useState<ErrorType | string | null | unknown>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const verifyLogged = async () => {
      if (users == null) {
        const storageAuth = await getAuthStorage()
        console.log('storageAuth', storageAuth)
        if (storageAuth) {
          console.log('dddd')
          api.defaults.headers.common.Authorization = `Bearer ${storageAuth.token}`
          await getUsers()
        }
      }
      setLoading(false)
    }
    verifyLogged()
  }, [])

  const getUsers: AuthContextData['getUsers'] = async () => {
    const res = await api.get(`/v1/users`)

    if (res.status !== 200) {
      return false
    }
    // set user
    setUsers(res.data)
    return users
  }

  const Login: AuthContextData['Login'] = async data => {
    try {
      setLoading(true)
      setError(null)

      // set header authorization
      api.defaults.headers.common.Authorization = process.env.REACT_APP_BASIC_ADMIN as string
      // request
      const res = await api.post('/v1/login', {
        grant_type: 'password',
        cpf: '',
        ...data,
      })

      if (res.status === 200) {
        // save token in storage
        if (res.data.user.user_type === '1' || res.data.user.user_type === '2') {
          await setAuthStorage(res.data)
          await setUser(res.data)

          // set token in header
          api.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`

          // get user
          //await getUsers()

          window.location.href = '/'
          setLoading(false)
        } else {
          setError('Usuário não autorizado')
          setLoading(false)
        }
      } else if (res.status === 401) {
        setError(res.data)
        console.log('error', res.data)
        setLoading(false)
      }
    } catch (e) {
      const err = e as AxiosError
      console.log('error', e)
      setError(err.response?.data)
      setLoading(false)
    }
  }

  function Logout() {
    removeAuthStorage()
    setUser(null)
    setUsers(null)
  }

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ signed: Boolean(users != null), user, loading, error, Login, Logout, getUsers }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.')
  }

  return context
}
