import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import ResumeCard from '../../components/ResumeCard'
import ReactTable from '../../components/ReactTable'
import { format } from 'date-fns'
import { ReturnButton } from '../../components/Buttons/ReturnButton'
import { BiEdit, BiExport, BiFilter, BiRadar, BiUser, BiWallet } from 'react-icons/bi'
import { useNavigate, useParams } from 'react-router-dom'
import { FiEye } from 'react-icons/fi'
import { api } from '../../services/api'
import { VscEdit, VscChecklist, VscCheck, VscChromeClose } from 'react-icons/vsc'
import { IconGeneralButton } from '../../components/Buttons/IconGeneralButton'
import ConfirmModal from '../../components/ConfirmModal'
import ConfirmModalExport from '../../components/ConfirmModal/ConfirmModalExport'
import { useFetch } from '../../hooks/useFetch'

const Quotes = () => {
  const baseUrl = 'v1/extract/weekly_deposits/'
  const { requestApi, error } = useFetch('')
  const navigate = useNavigate()
  const [pageNumber, setPageNumber] = useState(1)
  const [empresa, setEmpresa] = useState<any>({})
  const [habitational_unity, setHabitational_unity] = useState<any[]>([])
  const [quotes, setQuotes] = useState<any>({})
  const [habitational_unit_id, setHabitational_unit_id] = useState(0)
  const [ano, setAno] = useState(0)
  const [pageSize, setPageSize] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [data, setData] = useState<any[]>([])
  const [recot, setRecot] = useState<any[]>([])
  const [total, setTotal] = useState(0)
  const [editt, setEditt] = useState('')
  const toast = useToast()
  const { itemId } = useParams()
  const [editObj, setEditObj] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getData()
  }, [pageNumber, perPage, habitational_unit_id, ano, editt])

  async function getData() {
    try {
      console.log('Aqui:::', habitational_unit_id)
      setIsLoading(true)
      setRecot([])
      const getQuotes = await api.get(
        `v1/extract/weekly_deposits/${itemId}?habitational_unit_id=${habitational_unit_id}&pageNumber=${pageNumber}&perPage=${perPage}&ano=${ano}`,
      )
      const getEmpresa = await api.get(`v1/enterprises/${itemId}`)
      const getHabitational = await api.get(`v1/habitational_unity`)
      if (getHabitational.status === 200) {
        console.log('Aqui:::', getQuotes.data)
        setData(getQuotes.data.quotation)
        setEmpresa(getEmpresa.data.enterprise)
        const obj: any[] = []
        const hab_unitys: string[] = String(getEmpresa.data.enterprise.habitational_unity_id).split(';')
        for (let i = 0; i < hab_unitys.length; i++) {
          getHabitational.data.habitational_unity.map((j: any) => {
            console.log(j.habitational_unity_id)
            console.log(hab_unitys[i])
            j.habitational_unity_id === Number(hab_unitys[i]) ? obj.push(j) : console.log('Nao tem', j)
          })
        }
        setHabitational_unity(obj)
        setPageSize(getQuotes.data.paginateCount)
        setTotal(getQuotes.data.count)
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const changeStatus = async (id: number, status: number) => {
    try {
      const newStatus = status === 1 ? 0 : 1
      const getQuotes = await api.put(`v1/quotes/${id}`, {
        validation_status: newStatus,
      })
      console.log('FFFF::: ', getQuotes.data)
      setEditt(new Date().toString())
    } catch (error: any) {
      console.log(error)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Detalhes',
        columns: [
          {
            Header: 'Nº da semana',
            accessor: 'week_num',
          },
          {
            Header: 'Data de inicio',
            accessor: 'week_starts_at',
            Cell: ({ row }: any) => {
              return <Text>{format(new Date(row.original.week_starts_at), 'dd/MM/yy')}</Text>
            },
          },
          {
            Header: 'Data de fim',
            accessor: 'week_end_at',
            Cell: ({ row }: any) => {
              return <Text>{format(new Date(row.original.week_end_at), 'dd/MM/yy')}</Text>
            },
          },
          {
            Header: 'Aprovado?',
            accessor: 'validation_status',
            Cell: ({ row }: any) => {
              return row.original.validation_status === 1 ? (
                <Text color="green.500">Aprovado</Text>
              ) : (
                <Text color="red.500">Pendente</Text>
              )
            },
          },
          {
            Header: 'Valor',
            accessor: 'value_amount',
            Cell: ({ row }: any) => {
              return <Text color="green.500">{row.original.value_amount}</Text>
            },
          },
          {
            Header: 'Ação',
            accessor: 'actions',
            Cell: ({ row }: any) => (
              <Flex align="center">
                {ModalEdit(row)}
                {row.original.validation_status === 1 ? (
                  <IconGeneralButton
                    Icon={VscChromeClose}
                    color="red"
                    fontSize="25px"
                    onClick={() => {
                      changeStatus(row.original.quotation_id, Number(row.original.validation_status))
                      console.log('Editar', row.original)
                    }}
                  />
                ) : (
                  <IconGeneralButton
                    Icon={VscCheck}
                    color="#1890FF"
                    fontSize="25px"
                    onClick={() => {
                      changeStatus(row.original.quotation_id, Number(row.original.validation_status))
                      console.log('Editar', row.original)
                    }}
                  />
                )}
              </Flex>
            ),
          },
          {
            Header: 'Recotar',
            accessor: 'isRecort',
            Cell: ({ row }: any) => {
              return (
                <Checkbox
                  onChange={() => {
                    const items = recot
                    console.log('ID:::', row.original.quotation_id)
                    const itemToUpdate = items.find(item => item.quotation_id === row.original.quotation_id)
                    console.log('Aqui:::', itemToUpdate)
                    if (itemToUpdate) {
                      items.splice(items.indexOf(itemToUpdate), 1)
                    } else {
                      items.push(row.original)
                    }
                    setRecot(items)
                    console.log('Aqui:::', items)
                  }}
                  isChecked={row.original.isRecort}
                ></Checkbox>
              )
            },
          },
        ],
      },
    ],
    [],
  )

  const ModalEdit = (row: any) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [newValor, setNewValor] = useState('')

    const changeValue = async (id: number) => {
      try {
        const getQuotes = await api.put(`v1/quotes/${id}`, {
          value_amount: newValor,
        })
        setEditt(new Date().toString())
      } catch (error: any) {
        console.log(error)
      }
    }

    return (
      <>
        <IconGeneralButton
          Icon={VscEdit}
          color="#1890FF"
          fontSize="25px"
          onClick={() => {
            console.log('Editar', row.original)
            setEditObj(row.original)
            setNewValor(row.original.value_amount)
            onOpen()
          }}
        />
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Alteração de valor</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <Flex flexDir="column" mb="40px">
                <Box display="flex" flexDir="row">
                  <Box width="100%" mr="1vw">
                    <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                      Valor
                    </Text>
                    <Input
                      type="number"
                      placeholder="Ex: 589"
                      value={newValor}
                      onChange={e => setNewValor(e.target.value)}
                    />
                  </Box>
                </Box>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button mr={3} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                variant="ghost"
                onClick={() => {
                  changeValue(row.original.quotation_id)
                  onClose()
                }}
              >
                Salvar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

  return (
    <Flex flexDir="column" mt="7vh" ml="4vw" w="73vw">
      <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
        <Flex>
          <ReturnButton onClick={() => navigate(`/enterprises/${itemId}/edit`)} />
          <Text mb="20px" fontSize="24px" fontWeight="bold" color="#2D3748">
            Depósitos semanais de: {empresa.enterprise_name}
          </Text>
        </Flex>
        <Box>
          <Flex align="center" mb={5}>
            <Box maxW="md" mr="1">
              <FormControl>
                <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                  Unidade habitacional
                </FormLabel>
                <Select
                  placeholder="Selecione "
                  onChange={(e: any) => {
                    setHabitational_unit_id(e.target.value)
                  }}
                >
                  {habitational_unity.map((group, index) => (
                    <option key={index} value={group.habitational_unity_id}>
                      {group.room_type} ({group.pax} pessoas)
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <div style={{ width: 30 }} />
            <Box maxW="md" mr="1">
              <FormControl>
                <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                  Ano
                </FormLabel>
                <Select
                  placeholder="Selecione "
                  onChange={(e: any) => {
                    setAno(e.target.value)
                  }}
                >
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  {/* <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>
                  <option value="2030">2030</option>
                  <option value="2031">2031</option>
                  <option value="2032">2032</option>
                  <option value="2033">2033</option>
                  <option value="2034">2034</option>
                  <option value="2035">2035</option>
                  <option value="2036">2036</option>
                  <option value="2037">2037</option> */}
                </Select>
              </FormControl>
            </Box>
            <Box maxW="md" mr="1" mt={8}>
              <Button
                onClick={async () => {
                  const getQuotes = await api.put(`v1/weekly_deposits/${itemId}`, {
                    selects: recot,
                  })
                  navigate('..')
                }}
                colorScheme="red"
                mr="20px"
                rightIcon={<BiRadar />}
                variant="solid"
              >
                Cotar selecionados
              </Button>
            </Box>
          </Flex>
          {!isLoading && (
            <ReactTable
              columns={columns}
              pageNumber={pageNumber}
              data={data}
              totaPages={pageSize}
              onChange={(e: any) => {
                console.log('Akkkk:: ', e)
                setPageNumber(e.pageIndex + 1)
                setPerPage(e.sPageSize)
              }}
            />
          )}
        </Box>
      </Box>
    </Flex>
  )
}

export default Quotes
